import React from 'react'
import styles from './CreateFight.module.scss'
import Input from '../../Components/Input'
import Button from '../../Components/Button'
import api from '../../helpers/api'
import Dropzone from 'react-dropzone'
import { ReactComponent as UploadIcon } from '../../icons/common-file-text-upload.svg'
import Papa from 'papaparse'
import Loader from '../../Components/Loader'
import queryString from 'query-string'
import CreateFighter from '../../Components/Modals/CreateFighter'
import _ from 'lodash'
import chrono from 'chrono-node'
import moment from 'moment'
import CreateEvent from '../../Components/Modals/CreateEvent'

class CreateFight extends React.Component {
  state = {
    currentRow: undefined,
    hasCsv: false,
    rows: [],
    loading: false,
    suggestedFighters: {
      one: [],
      two: [],
    },
    modalOpened: false,
    eventModalOpened: false,
    creatingSuggestionFighter: 'one',
    events: [],
  }

  componentDidMount() {
    this.requestEvents()
  }

  requestEvents() {
    this.setLoading(true)

    api
      .get(`events/`)
      .then((result) => {
        this.setEvents(result.data.content)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  setEvents = (events) => {
    this.setState({
      events,
    })
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
    })
  }

  openModal = () => {
    this.setState({
      modalOpened: true,
    })
  }

  openEventModal = () => {
    this.setState({
      eventModalOpened: true,
    })
  }

  closeEventModal = () => {
    this.setState({
      eventModalOpened: false,
    })
  }

  nextRow = () => {
    const { currentRow, rows, events } = this.state

    const rowNumber = typeof currentRow === 'undefined' ? 0 : currentRow + 1

    const row = rows[rowNumber]

    if (!row) {
      return
    }

    this.setState(
      {
        currentRow:
          typeof currentRow === 'undefined' ? 0 : this.state.currentRow + 1,
      },
      () => {
        this.requestSuggestedFighters(row.fighter_1_name, row.fighter_2_name)

        events.forEach((event) => {
          if (event.name === row.fight_tournament) {
            this.setRowField('event_id', event.id)
          }
        })
      }
    )
  }

  getCreatingFighterDetails = () => {
    const { hasCsv, rows, currentRow, creatingSuggestionFighter } = this.state

    const row = rows[currentRow]

    if (!hasCsv || !row) {
      return null
    }
    const index = creatingSuggestionFighter === 'one' ? '1' : '2'
    const name = row[`fighter_${index}_name`].split(' ')

    return {
      firstName: name[0],
      lastName: name.slice(1).join(' ') || '',
      country: row[`fighter_${index}_country`],
      gym: row[`fighter_${index}_gym`],
      state: row[`fighter_${index}_state`],
      weight: row[`fighter_${index}_weight`],
      memberId: row[`fighter_${index}_member_id`],
    }
  }

  setCreatingSuggestionFighter = (number) => {
    this.setState({
      creatingSuggestionFighter: number,
    })
  }

  setSuggestedFighter = (number, data) => {
    if (['one', 'two'].includes(number)) {
      this.setState({
        suggestedFighters: {
          ...this.state.suggestedFighters,
          [number]: data,
        },
      })
    }
  }

  requestSuggestedFighters = (firstFighterName, secondFighterName) => {
    const { token } = this.props

    const fighterOne = firstFighterName.split(' ')
    const fighterTwo = secondFighterName.split(' ')

    const firstFighterQuery = {
      firstName: fighterOne[0],
      lastName: fighterOne[1],
    }

    const secondFighterQuery = {
      firstName: fighterTwo[0],
      lastName: fighterTwo[1],
    }

    api
      .get(`auth/boxers/?${queryString.stringify(firstFighterQuery)}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        this.setSuggestedFighter('one', response.data.content)

        api
          .get(`auth/boxers/?${queryString.stringify(secondFighterQuery)}`, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            this.setSuggestedFighter('two', response.data.content)

            this.setLoading(false)
          })
      })
  }

  enhanceRows = (rows) => {
    return rows.map((row) => {
      let winner

      if (row.fight_result === 'D') {
        winner = 'draw'
      } else if (row.fight_result === 'EXH') {
        winner = 'exhibition'
      } else if (
        row.fighter_1_name.toLowerCase() === row.fight_winner.toLowerCase()
      ) {
        winner = 'one'
      } else if (
        row.fighter_2_name.toLowerCase() === row.fight_winner.toLowerCase()
      ) {
        winner = 'two'
      } else {
        // if could not set a winner set first
        winner = 'one'
      }

      return Object.assign({}, row, {
        fight_winner_number: winner,
        parsed_fight_date: chrono.en_GB.parseDate(row.fight_date),
      })
    })
  }

  setRows = (rows) => {
    this.setState(
      {
        hasCsv: true,
        rows: this.enhanceRows(rows),
      },
      () => this.nextRow()
    )
  }

  setLoading = (state) => {
    this.setState({
      loading: state,
    })
  }

  setRowField = (field, value) => {
    this.setState((prevState) => {
      const rowNumber = prevState.currentRow
      return {
        rows: [
          ...prevState.rows.slice(0, rowNumber),
          {
            ...prevState.rows[rowNumber],
            [field]: value,
          },
          ...prevState.rows.slice(rowNumber + 1),
        ],
      }
    })
  }

  parseCsv = (file) => {
    this.setLoading(true)
    Papa.parse(file.shift(), {
      header: true,
      encoding: 'utf-8',
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        const rows = results.data.length ? results.data : []
        this.setRows(rows)
      },
    })
  }

  resetState = () => {
    this.setState({
      currentRow: undefined,
      hasCsv: false,
      rows: [],
      loading: false,
      suggestedFighters: {
        one: [],
        two: [],
      },
    })
  }

  submitRow = () => {
    const { currentRow, rows } = this.state
    const { token } = this.props
    const row = rows[currentRow]

    this.setLoading(true)

    api
      .post(
        'auth/fights',
        {
          result: row.fight_result ? row.fight_result : 'UNK',
          location: row.fight_location,
          tournament: row.fight_tournament,
          verified: _.get(row, 'fight_verified', '').toLowerCase() === 'yes',
          date: row.parsed_fight_date,
          notes: row.fight_notes,
          rounds: row.fight_rounds,
          roundLength: row.fight_round_length,
          event: row.event_id ? { id: row.event_id } : null,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // Fight was created successfully let's attach fighters
        if (response.status === 200) {
          const fightId = response.data.id
          const fighterOne = row.fighter_1_id
            ? {
                boxer: {
                  id: row.fighter_1_id,
                },
                country: row.fighter_1_country,
                gym: row.fighter_1_gym,
                state: row.fighter_1_state,
                weight: row.fighter_1_weight,
                winsAtTime: row.fighter_1_wins_at_time,
                lossesAtTime: row.fighter_1_losses_at_time,
                drawsAtTime: row.fighter_1_draws_at_time,
                winner: row.fight_winner_number === 'one',
              }
            : {
                name: row.fighter_1_name,
                country: row.fighter_1_country,
                gym: row.fighter_1_gym,
                state: row.fighter_1_state,
                weight: row.fighter_1_weight,
                winsAtTime: row.fighter_1_wins_at_time,
                lossesAtTime: row.fighter_1_losses_at_time,
                drawsAtTime: row.fighter_1_draws_at_time,
                winner: row.fight_winner_number === 'one',
              }

          const fighterTwo = row.fighter_2_id
            ? {
                boxer: {
                  id: row.fighter_2_id,
                },
                country: row.fighter_2_country,
                gym: row.fighter_2_gym,
                state: row.fighter_2_state,
                weight: row.fighter_2_weight,
                winsAtTime: row.fighter_2_wins_at_time,
                lossesAtTime: row.fighter_2_losses_at_time,
                drawsAtTime: row.fighter_2_draws_at_time,
                winner: row.fight_winner_number === 'two',
              }
            : {
                name: row.fighter_2_name,
                country: row.fighter_2_country,
                gym: row.fighter_2_gym,
                state: row.fighter_2_state,
                weight: row.fighter_2_weight,
                winsAtTime: row.fighter_2_wins_at_time,
                lossesAtTime: row.fighter_2_losses_at_time,
                drawsAtTime: row.fighter_2_draws_at_time,
                winner: row.fight_winner_number === 'two',
              }

          api
            .post(`auth/fights/${fightId}/`, [fighterOne, fighterTwo], {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                if (this.state.currentRow + 1 >= rows.length) {
                  this.resetState()
                } else {
                  this.nextRow()
                }
              }
            })
            .finally(() => {
              this.setLoading(false)
            })
        }
      })
  }

  render() {
    const {
      hasCsv,
      rows,
      loading,
      currentRow,
      suggestedFighters,
      events,
    } = this.state
    const creatingFighter = this.getCreatingFighterDetails()

    const row = rows[currentRow]
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <h2>Create Fights</h2>

          {loading && <Loader />}

          {!hasCsv && !loading ? (
            <Dropzone
              onDrop={(acceptedFiles) => {
                this.parseCsv(acceptedFiles)
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <section className={styles.dropzone}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <UploadIcon />
                    <p>
                      Drag 'n' drop CSV data file here, or click to select it
                      from the disk
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          ) : null}

          {hasCsv && rows.length && !loading ? (
            <>
              <h3>
                Fight {currentRow + 1}/{rows.length}
              </h3>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  this.submitRow()
                }}
              >
                <Input
                  id="date"
                  label="Date"
                  type="date"
                  onChange={(value) =>
                    this.setRowField('parsed_fight_date', value)
                  }
                  value={row.parsed_fight_date}
                />

                <Input
                  id="result"
                  label="Result"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fight_result', e.target.value)
                  }
                  value={row.fight_result}
                />

                <Input
                  id="rounds"
                  label="Rounds"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fight_rounds', e.target.value)
                  }
                  value={row.fight_rounds}
                />

                <Input
                  id="roundLength"
                  label="Round Length"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fight_round_length', e.target.value)
                  }
                  value={row.fight_round_length}
                />

                <Input
                  id="location"
                  label="Location"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fight_location', e.target.value)
                  }
                  value={row.fight_location}
                />

                <div className={styles.fighterGroup}>
                  <Input
                    className={styles.longInput}
                    id="event"
                    label="Event"
                    type="select"
                    onChange={(e) =>
                      this.setRowField('event_id', e.target.value)
                    }
                    value={row.event_id}
                    options={[
                      {
                        key: '',
                        value: 'Choose Event',
                      },
                      ...events.map((event) => ({
                        key: event.id,
                        value: event.name,
                      })),
                    ]}
                  />
                  <Button
                    onClick={() => {
                      this.openEventModal()
                    }}
                    type="primary"
                  >
                    Add
                  </Button>
                </div>

                <Input
                  id="notes"
                  label="Notes"
                  type="textarea"
                  onChange={(e) =>
                    this.setRowField('fight_notes', e.target.value)
                  }
                  value={row.fight_notes}
                />

                <Input
                  id="winner"
                  label="Winner"
                  type="select"
                  onChange={(e) =>
                    this.setRowField('fight_winner_number', e.target.value)
                  }
                  value={row.winner_number}
                  options={[
                    { key: 'one', value: 'Fighter 1' },
                    { key: 'two', value: 'Fighter 2' },
                  ]}
                  disabled={row.fight_result === 'D'}
                />

                <Input
                  id="verified"
                  label="Verified"
                  type="checkbox"
                  onChange={(checked) =>
                    this.setRowField('fight_verified', checked ? 'Yes' : 'No')
                  }
                  value={
                    row.fight_verified &&
                    row.fight_verified.toLowerCase() === 'yes'
                  }
                />

                <h4>Fighter 1</h4>

                <Input
                  id="fighterOneId"
                  label="Existing Boxer"
                  type="select"
                  onChange={(e) => {
                    this.setRowField('fighter_1_id', e.target.value)
                  }}
                  value={row.fighter_1_id}
                  options={[
                    {
                      key: '',
                      value: 'Choose Boxer',
                    },
                    ...suggestedFighters.one.map((fighter) => ({
                      key: fighter.id,
                      value: `${fighter.firstName} ${
                        fighter.lastName
                      } - Weight: ${
                        fighter.weight || '-'
                      }kg - DoB: ${moment
                        .utc(fighter.dateOfBirth)
                        .format('DD/MM/YYYY')} - Gym: ${fighter.gym}`,
                    })),
                  ]}
                />
                <div className={styles.or} />
                <div className={styles.fighterGroup}>
                  <Input
                    className={styles.longInput}
                    id="fighterOneName"
                    label="Boxer Name"
                    type="text"
                    onChange={(e) =>
                      this.setRowField('fighter_1_name', e.target.value)
                    }
                    value={row.fighter_1_name}
                  />
                  <Button
                    onClick={() => {
                      this.setCreatingSuggestionFighter('one')
                      this.openModal()
                    }}
                    type="primary"
                  >
                    Add
                  </Button>
                </div>
                <Input
                  id="fighterOneCountry"
                  label="Country"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_country', e.target.value)
                  }
                  value={row.fighter_1_country}
                />
                <Input
                  id="fighterOneGym"
                  label="Gym"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_gym', e.target.value)
                  }
                  value={row.fighter_1_gym}
                />
                <Input
                  id="fighterOneState"
                  label="State/region"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_state', e.target.value)
                  }
                  value={row.fighter_1_state}
                />
                <Input
                  id="fighterOneWeight"
                  label="Weight"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_weight', e.target.value)
                  }
                  value={row.fighter_1_weight}
                />
                <Input
                  id="fighterOneWinsAtTime"
                  label="Wins At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_wins_at_time', e.target.value)
                  }
                  value={row.fighter_1_wins_at_time}
                />
                <Input
                  id="fighterOneLossesAtTime"
                  label="Losses At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_losses_at_time', e.target.value)
                  }
                  value={row.fighter_1_losses_at_time}
                />
                <Input
                  id="fighterOneDrawsAtTime"
                  label="Draws At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_1_draws_at_time', e.target.value)
                  }
                  value={row.fighter_1_draws_at_time}
                />

                <h4>Fighter 2</h4>

                <Input
                  id="fighterTwoId"
                  label="Existing Boxer"
                  type="select"
                  onChange={(e) =>
                    this.setRowField('fighter_2_id', e.target.value)
                  }
                  value={row.fighter_2_id}
                  options={[
                    {
                      key: '',
                      value: 'Choose Boxer',
                    },
                    ...suggestedFighters.two.map((fighter) => ({
                      key: fighter.id,
                      value: `${fighter.firstName} ${
                        fighter.lastName
                      } - Weight: ${
                        fighter.weight || '-'
                      }kg - DoB: ${moment
                        .utc(fighter.dateOfBirth)
                        .format('DD/MM/YYYY')} - Gym: ${fighter.gym}`,
                    })),
                  ]}
                />
                <div className={styles.or} />
                <div className={styles.fighterGroup}>
                  <Input
                    className={styles.longInput}
                    id="fighterTwoName"
                    label="Boxer Name"
                    type="text"
                    onChange={(e) =>
                      this.setRowField('fighter_2_name', e.target.value)
                    }
                    value={row.fighter_2_name}
                  />
                  <Button
                    onClick={() => {
                      this.setCreatingSuggestionFighter('two')
                      this.openModal()
                    }}
                    type="primary"
                  >
                    Add
                  </Button>
                </div>
                <Input
                  id="fighterTwoCountry"
                  label="Country"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_country', e.target.value)
                  }
                  value={row.fighter_2_country}
                />
                <Input
                  id="fighterTwoGym"
                  label="Gym"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_gym', e.target.value)
                  }
                  value={row.fighter_2_gym}
                />
                <Input
                  id="fighterTwoState"
                  label="State/region"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_state', e.target.value)
                  }
                  value={row.fighter_2_state}
                />
                <Input
                  id="fighterTwoWeight"
                  label="Weight"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_weight', e.target.value)
                  }
                  value={row.fighter_2_weight}
                />
                <Input
                  id="fighterTwoWinsAtTime"
                  label="Wins At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_wins_at_time', e.target.value)
                  }
                  value={row.fighter_2_wins_at_time}
                />
                <Input
                  id="fighterTwoLossesAtTime"
                  label="Losses At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_losses_at_time', e.target.value)
                  }
                  value={row.fighter_2_losses_at_time}
                />
                <Input
                  id="fighterTwoDrawsAtTime"
                  label="Draws At Time"
                  type="text"
                  onChange={(e) =>
                    this.setRowField('fighter_2_draws_at_time', e.target.value)
                  }
                  value={row.fighter_2_draws_at_time}
                />

                <Button isSubmit>Create Fight</Button>
              </form>
              <hr />
            </>
          ) : null}
        </div>
        <CreateFighter
          initialState={creatingFighter}
          token={this.props.token}
          isOpen={this.state.modalOpened}
          onClose={this.closeModal}
          onCreated={(boxer) => {
            this.setSuggestedFighter(this.state.creatingSuggestionFighter, [
              boxer,
            ])
          }}
        />
        <CreateEvent
          initialState={{ name: row ? row.fight_tournament || '' : '' }}
          token={this.props.token}
          isOpen={this.state.eventModalOpened}
          onClose={this.closeEventModal}
          onCreated={(event) => {
            this.setEvents([event, ...events])
            this.setRowField('fight_tournament', event.name)
            this.setRowField('event_id', event.id)
            this.closeEventModal()
          }}
        />
      </div>
    )
  }
}

export default CreateFight
