import React from 'react'
import styles from './Home.module.scss'
import { Col, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Loader from '../Components/Loader'
import api from '../helpers/api'
import queryString from 'query-string'
import Featured from '../Components/Featured'
import { splitToChunks } from '../helpers/utility'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

class Home extends React.Component {
  state = {
    fighters: [],
    fightersLoaded: false,
    events: [],
    eventsLoaded: false,
  }

  setEvents = (events) => {
    this.setState({
      events,
      eventsLoaded: true,
    })
  }

  setFighters = (fighters) => {
    this.setState({
      fighters,
      fightersLoaded: true,
    })
  }

  requestFighters = () => {
    const query = {
      images: true,
      size: 21,
    }

    api.get(`boxers/?${queryString.stringify(query)}`).then((result) => {
      this.setFighters(result.data.content, result.data.last)
    })
  }

  requestEvents = () => {
    const query = {
      size: 5,
    }

    api.get(`events/?${queryString.stringify(query)}`).then((result) => {
      this.setEvents(result.data.content)
    })
  }

  componentDidMount() {
    this.requestFighters()
    this.requestEvents()
  }

  render() {
    const { fightersLoaded, fighters, events, eventsLoaded } = this.state

    return (
      <>
        <Row className={styles.row}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={styles.title}>Welcome</div>
            <div className={styles.container}>
              <p>
                Boxing Book is a freely accessible amateur boxing database. We
                collect, store, and display local and international IBA
                sanctioned amateur boxing events. Established in 2020 our
                database holds over 30,000 fights and continues to grow rapidly.
                Please contact us at{' '}
                <a href={'mailto:data@boxingbook.com'}>data@boxingbook.com</a>{' '}
                if you want to collaborate or to contribute your fight record.
              </p>
            </div>
          </Col>
        </Row>
        {eventsLoaded && events.length ? (
          <Row className={styles.row}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Link to={'events'}>
                <div className={styles.title}>Updated Events</div>
              </Link>
              <div className={styles.events}>
                {eventsLoaded ? (
                  <>
                    {events.slice(0, 4).map((event) => (
                      <Card
                        key={event.id}
                        sx={{
                          maxWidth: 250,
                          minWidth: 250,
                          margin: '8px 16px 16px 8px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <LocationOnOutlinedIcon fontSize="small" />{' '}
                            {event.country}, {event.state}
                          </Typography>
                          <Typography fontSize={'1.1rem'} component="div">
                            {event.name}
                          </Typography>
                          <Typography sx={{ marginTop: '8px' }} variant="body2">
                            {moment.utc(event.date).format('D MMMM YYYY')}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            sx={{ justifyContent: 'flex-end', display: 'flex' }}
                            size="small"
                            onClick={() =>
                              this.props.history.push(`events/${event.id}`)
                            }
                          >
                            Learn More
                          </Button>
                        </CardActions>
                      </Card>
                    ))}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </Col>
          </Row>
        ) : null}

        <Row className={styles.updated}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Link to={'search'}>
              <div className={styles.title}>Updated Fighters</div>
            </Link>
            {fightersLoaded ? (
              <>
                <div className={styles.recent}>
                  {splitToChunks(fighters.slice(0, 21), 3).map(
                    (column, index) => (
                      <div key={`column-${index}`} className={styles.fighters}>
                        {column.map((fighter) => (
                          <Featured
                            id={fighter}
                            key={`fighter-${fighter.id}`}
                            fighter={fighter}
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
                <div className={styles.seeAll}>
                  <Link to="/search">See All Fighters</Link>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
      </>
    )
  }
}

export default Home
