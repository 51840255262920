import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Col, Row } from 'react-flexbox-grid'
import Loader from '../Components/Loader'
import Input from '../Components/Input'
import queryString from 'query-string'
import api from '../helpers/api'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { ReactComponent as NotFoundIcon } from '../icons/search-remove.svg'
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg'
import { ReactComponent as BinIcon } from '../icons/bin.svg'
import { ReactComponent as ArrowUp } from '../icons/arrow-up-1.svg'
import { ReactComponent as ArrowDown } from '../icons/arrow-down-1.svg'
import styles from './Search.module.scss'
import cx from 'classnames'
import FightersTable from '../Components/FightersTable'
import Button from '../Components/Button'
import { Link } from 'react-router-dom'

function weighText(value) {
  return `${value}kg`
}
function valueText(value) {
  return `${value}`
}

function Search({ loggedIn, location, history }) {
  const [width, setWidth] = useState(window.innerWidth)
  const searchBarData = location.state?.data?.filter(
    (item) => item?.__autocomplete_indexName === 'boxers'
  )
  const [searchData, setSearchData] = useState(searchBarData)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  const [fromSearch, setFromSearch] = useState(
    searchData ? searchData?.length !== 0 : false
  )

  const [resultsLoaded, setResultsLoaded] = useState(fromSearch)
  const [results, setResults] = useState(searchData ? searchData : undefined)

  // If new data from search refresh
  useEffect(() => {
    if (!_.isEqual(results, searchBarData) && searchBarData?.length > 0) {
      setResults(searchBarData)
      setResultsLoaded(true)
      setFromSearch(false)
      setSearchData([])
      history.replace()
    }
  }, [searchBarData, results, history])

  const perPage = 15
  const [currentPage, setCurrentPage] = useState(1)
  const [isLast, setIsLast] = useState(fromSearch)
  const parsedQuery = queryString.parse(location.search)
  const minDistance = 2

  const defaultExperience = [0, 100]
  const [experience, setExperience] = React.useState(defaultExperience)

  const defaultWeight = [20, 100]
  const [weight, setWeight] = React.useState(defaultWeight)

  const defaultAge = [0, 60]
  const [age, setAge] = React.useState(defaultAge)

  // Clear the data from the search box if it exists after initial load
  useEffect(() => {
    if (fromSearch && _.isEqual(results, fromSearch)) {
      setResultsLoaded(true)
      setFromSearch(false)
      setSearchData([])
      history.replace()
    }
  }, [fromSearch, history, results])

  const defaultAgeEnabled = false
  const [ageEnabled, setAgeEnabled] = React.useState(defaultAgeEnabled)

  const defaultExperienceEnabled = false
  const [experienceEnabled, setExperienceEnabled] = React.useState(
    defaultExperienceEnabled
  )

  const defaultWeightEnabled = false
  const [weightEnabled, setWeightEnabled] = React.useState(defaultWeightEnabled)

  function handleChangeExperience(event, newValue, activeThumb) {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setExperience([
        Math.min(newValue[0], experience[1] - minDistance),
        experience[1],
      ])
    } else {
      setExperience([
        experience[0],
        Math.max(newValue[1], experience[0] + minDistance),
      ])
    }
  }

  function handleChangeWeight(event, newValue, activeThumb) {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setWeight([Math.min(newValue[0], weight[1] - minDistance), weight[1]])
    } else {
      setWeight([weight[0], Math.max(newValue[1], weight[0] + minDistance)])
    }
  }

  function handleChangeAge(event, newValue, activeThumb) {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setAge([Math.min(newValue[0], age[1] - minDistance), age[1]])
    } else {
      setAge([age[0], Math.max(newValue[1], age[0] + minDistance)])
    }
  }

  // function handleChangeEnableAge(event, newValue, activeThumb) {
  //   setAgeEnabled(newValue)
  // }

  const [queryData, setQueryData] = useState({
    quickSearch: _.get(parsedQuery, 'quickSearch', undefined),
  })

  const [searchForm, setSearchForm] = useState({
    showAdvanced: !isMobile,
    firstName: '',
    lastName: '',
    gender: 'male',
    fights: '',
    country: 'Australia',
    state: '',
    ageQueryType: 'custom',
    fightsQueryType: 'custom',
    weightQueryType: 'custom',
    age,
    weight,
    experience,
  })

  function setFormValue(field, value) {
    setSearchForm({ ...searchForm, [field]: value })
  }

  function setResult(data, isLast) {
    setResults(data)
    setResultsLoaded(true)
    setIsLast(isLast)
  }

  function setPage(page) {
    setCurrentPage(page)
    setResultsLoaded(false)
    setResults([])
    requestData()
  }

  function search(query) {
    setCurrentPage(1)
    setResultsLoaded(false)
    let props = ['firstName', 'lastName']

    if (query.showAdvanced) {
      props = props.concat(['gender', 'country', 'state'])
      if (ageEnabled) {
        props = props.concat('ageQueryType', 'age')
      }
      if (experienceEnabled) {
        props = props.concat('fightsQueryType', 'fights', 'experience')
      }
      if (weightEnabled) {
        props = props.concat('weightQueryType', 'weight')
      }
    }

    let myQuery = {}
    props.forEach(function (prop) {
      myQuery[prop] = query[prop]
    })
    setQueryData(_.pick(query, props))
  }

  function clearSearch() {
    setSearchForm({
      showAdvanced: true,
      firstName: '',
      lastName: '',
      gender: 'male',
      fights: '',
      country: 'Australia',
      state: '',
      ageQueryType: 'custom',
      fightsQueryType: 'custom',
      weightQueryType: 'custom',
    })
    setCurrentPage(1)
    setResultsLoaded(false)
    setQueryData({ quickSearch: undefined })
    setAge(defaultAge)
    setExperience(defaultExperience)
    setWeight(defaultWeight)

    setAgeEnabled(defaultAgeEnabled)
    setWeightEnabled(defaultWeightEnabled)
    setExperienceEnabled(defaultExperienceEnabled)
  }

  function requestData() {
    const {
      quickSearch,
      firstName,
      lastName,
      gender,
      fights,
      country,
      state,
      ageQueryType,
      fightsQueryType,
      weightQueryType,
    } = queryData

    const query = {
      size: perPage,
      page: currentPage - 1,
      sortBy: 'createdAt',
      sortOrder: 'DESC',
      quickSearch,
      firstName,
      lastName,
      gender,
      fights,
      country,
      state,
      ageQueryType,
      fightsQueryType,
      weightQueryType,
    }

    if (ageEnabled) {
      // Increase the max values at slider max to ensure query gets everyone
      const finalAge = age.slice()
      if (finalAge[1] === defaultAge[1]) {
        finalAge[1] = 100
      }
      query.age = finalAge.join(' - ')
    }

    if (weightEnabled) {
      const finalWeight = weight.slice()
      if (finalWeight[1] === defaultWeight[1]) {
        finalWeight[1] = 200
      }
      query.weight = finalWeight.join(' - ')
    }

    if (experienceEnabled) {
      const finalExperience = experience.slice()
      if (finalExperience[1] === defaultExperience[1]) {
        finalExperience[1] = 500
      }
      query.fights = finalExperience.join(' - ')
    }
    const finalQuery = {
      ...query,
    }

    for (const key of Object.keys(finalQuery)) {
      if (
        finalQuery[key] === '' ||
        (key === 'country' && finalQuery[key] === 'All')
      ) {
        delete finalQuery[key]
      }
    }

    api.get(`boxers/?${queryString.stringify(finalQuery)}`).then((result) => {
      setResult(result.data.content, result.data.last)
    })
  }

  const getData = useCallback(() => {
    requestData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!resultsLoaded && !results) {
      getData()
    }
  }, [getData, resultsLoaded, results])

  useEffect(() => {
    if (!resultsLoaded) {
      requestData()
    }
    // eslint-disable-next-line
  }, [queryData, resultsLoaded])

  const hasSearchFilter =
    [
      queryData.quickSearch,
      queryData.firstName,
      queryData.lastName,
      queryData.state,
      ageEnabled,
      weightEnabled,
      experienceEnabled,
    ].some((property) => !!property) && resultsLoaded

  return (
    <Row className={styles.row}>
      <Col xs={12} sm={12} md={3} lg={3}>
        <div className={styles.form}>
          <h2>Filter Results</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              search(searchForm)
            }}
          >
            <div
              className={styles.advancedLink}
              onClick={() =>
                setFormValue('showAdvanced', !searchForm.showAdvanced)
              }
            >
              Advanced Search
              {searchForm.showAdvanced ? <ArrowUp /> : <ArrowDown />}
            </div>

            {searchForm.showAdvanced ? (
              <>
                <Input
                  type="text"
                  id="firstName"
                  label="First Name"
                  onChange={(e) => setFormValue('firstName', e.target.value)}
                  autoComplete="off"
                  data-lpignore="true"
                  value={searchForm.firstName}
                />

                <Input
                  type="text"
                  id="lastName"
                  label="Last Name"
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={(e) => setFormValue('lastName', e.target.value)}
                  value={searchForm.lastName}
                />
                <Input
                  type="radio"
                  id="gender"
                  label="Gender"
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={(e) => setFormValue('gender', e.target.value)}
                  value={searchForm.gender}
                  options={[
                    { value: 'Male', key: 'male' },
                    { value: 'Female', key: 'female' },
                  ]}
                />
                <Input
                  type="checkbox"
                  id="ageEnabled"
                  label="Age"
                  autoComplete="off"
                  data-lpignore="true"
                  value={ageEnabled}
                  onChange={(checked) => setAgeEnabled(checked)}
                />
                <Input
                  type="slider"
                  id="age"
                  label=""
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={handleChangeAge}
                  value={age}
                  disabled={!ageEnabled}
                  min={defaultAge[0]}
                  max={defaultAge[1]}
                  valueText={valueText}
                />
                <Input
                  type="checkbox"
                  id="experienceEnabled"
                  label="Fight experience"
                  autoComplete="off"
                  data-lpignore="true"
                  value={experienceEnabled}
                  onChange={(checked) => setExperienceEnabled(checked)}
                />
                <Input
                  type="slider"
                  id="experience"
                  label=""
                  autoComplete="off"
                  data-lpignore="true"
                  disabled={!experienceEnabled}
                  onChange={handleChangeExperience}
                  valueText={valueText}
                  value={experience}
                  min={defaultExperience[0]}
                  max={defaultExperience[1]}
                />
                <Input
                  type="checkbox"
                  id="weightEnabled"
                  label="Weight (kg)"
                  autoComplete="off"
                  data-lpignore="true"
                  value={weightEnabled}
                  onChange={(checked) => setWeightEnabled(checked)}
                />
                <Input
                  type="slider"
                  id="weight"
                  label=""
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={handleChangeWeight}
                  disabled={!weightEnabled}
                  valueText={weighText}
                  value={weight}
                  min={defaultWeight[0]}
                  max={defaultWeight[1]}
                />
                <Input
                  type="country"
                  label="Country"
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={(e) => setFormValue('country', e.target.value)}
                  value={searchForm.country}
                />

                <Input
                  type="state"
                  label="State/region"
                  autoComplete="off"
                  data-lpignore="true"
                  onChange={(e) => setFormValue('state', e.target.value)}
                  value={searchForm.state}
                  country={searchForm.country}
                />
                <Button
                  isSubmit={true}
                  message={
                    hasSearchFilter ? (
                      <span
                        className={styles.clear}
                        onClick={() => clearSearch()}
                      >
                        <BinIcon />
                        Clear Filter
                      </span>
                    ) : null
                  }
                >
                  <SearchIcon />
                  Filter
                </Button>
              </>
            ) : null}
          </form>
        </div>
      </Col>
      <Col xs={12} sm={12} md={9} lg={9}>
        <div className={styles.results}>
          <div className={styles.top}>
            <h2>Results</h2>
            {loggedIn && <Link to="/admin/boxer/create">Create Boxer</Link>}
          </div>
          {resultsLoaded ? (
            results?.length > 0 ? (
              <>
                <FightersTable fighters={results} />
                <div className={styles.pagination}>
                  <button
                    className={cx({
                      [styles.prev]: true,
                      [styles.disabled]: currentPage === 1,
                    })}
                    onClick={() => setPage(currentPage - 1)}
                  >
                    <ArrowLeft />
                    Previous
                  </button>
                  <button
                    className={cx({
                      [styles.next]: true,
                      [styles.disabled]: isLast,
                    })}
                    onClick={() => setPage(currentPage + 1)}
                  >
                    Next
                    <ArrowRight />
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.notfound}>
                <NotFoundIcon />
                <span>Nothing here matches your search</span>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Search
