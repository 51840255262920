import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styles from './confirm.module.scss'

const confirm = (onConfirm, text) =>
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={styles.confirm}>
          <h1>Are you sure?</h1>
          <p>{text}</p>
          <button onClick={onClose}>No</button>
          <button
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            Yes, Do it!
          </button>
        </div>
      )
    },
  })

export default confirm
