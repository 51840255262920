import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './Login.module.scss'
import Input from '../Components/Input'
import Button from '../Components/Button'

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  }

  setFormField = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    })
  }

  componentDidMount() {
    const { loggedIn, history } = this.props

    if (loggedIn) {
      history.push('/')
    }
  }

  render() {
    const { loggedIn, login, loginError, loggingIn } = this.props
    const { email, password } = this.state

    if (loggedIn) {
      return <Redirect to="/" />
    }

    const hasError = !!loginError

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <h2>Login</h2>

          {loginError && (
            <div className={styles.errorMessage}>{loginError}</div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault()
              login(email, password)
            }}
          >
            <Input
              id="email"
              label="Email"
              type="email"
              onChange={(e) => this.setFormField('email', e.target.value)}
              value={email}
              hasError={hasError}
            />

            <Input
              id="password"
              label="Password"
              type="password"
              onChange={(e) => this.setFormField('password', e.target.value)}
              value={password}
              hasError={hasError}
            />
            <Button isLoading={loggingIn} isSubmit>
              Login
            </Button>
          </form>
        </div>
      </div>
    )
  }
}

export default Login
