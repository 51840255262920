import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { ReactComponent as PhoneIcon } from '../icons/phone.svg'
import { ReactComponent as EmailIcon } from '../icons/envelope.svg'
import styles from './Contact.module.scss'

class Contact extends React.Component {
  render() {
    return (
      <Row className={styles.row}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={styles.container}>
            <h1>About Us</h1>
            <p>
              Boxing Book is a freely accessible website for collecting and
              providing all local and international level AIBA sectioned boxing
              events worldwide.
            </p>
            <p>
              We were established in 2020 and are passionate about creating a
              reliable and easy to use database for the athletes, coaches,
              officials and fans alike to use for the purposes of expanding and
              connecting the amateur boxing community.
            </p>
            <p>
              We believe a consistent week-by-week database of this nature will
              propagate the sport greatly specifically improving athlete safety
              and development, member participation, international
              collaborations and the overall community.
            </p>
            <p>
              If you are an individual or a boxing organisation and would like
              to collaborate with us please contact us at{' '}
              <a href={'mailto:admin@boxingbook.com'}>admin@boxingbook.com</a>.
            </p>
            <h1>Get in touch</h1>
            <h3>Do you have a question? Leave us a note!</h3>
            <p>We'd love to hear from you!</p>
            <p className={styles.contacts}>
              <PhoneIcon /> <a href="tel:+61447763946">+61447763946</a>
            </p>
            <p className={styles.contacts}>
              <EmailIcon />
              <a href="mailto:admin@boxingbook.com">admin@boxingbook.com</a>
            </p>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Contact
