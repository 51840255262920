import React from 'react'
import Modal from 'react-responsive-modal'
import styles from './Modal.module.scss'
import CreateEventForm from '../../Pages/Admin/CreateEvent'

class CreateEvent extends React.Component {
  render() {
    const { isOpen, onClose, token, initialState, onCreated } = this.props
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        center
        classNames={{
          modal: styles.modal,
          overlay: styles.overlay,
        }}
      >
        <CreateEventForm
          initialState={initialState}
          token={token}
          onCreated={onCreated}
        />
      </Modal>
    )
  }
}

export default CreateEvent
