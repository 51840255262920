import React from 'react'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import searchClient from '../helpers/searchClient'
import Autocomplete from './Autocomplete'
import { useHistory } from 'react-router-dom'
import BoxerHit from './BoxerHit'
import EventHit from './EventHit'

function getIndex(item) {
  return item ? item['__autocomplete_indexName'] : null
}
function getItemUrl(item) {
  //when ur using keyboard it passes in {item: {}, state: {}} as opposed to just item.
  if (!item.objectID && item.item) {
    item = item.item
  }
  switch (getIndex(item)) {
    case 'boxers':
      return `/fighter/${item.objectID}`
    case 'events':
      return `/events/${item.objectID}`
    default:
      return
  }
}

function HeaderSearch() {
  //We need to grab the router history here and pass in as the autocomplete components exist
  //outside the router and will fail if you try to use it.
  const history = useHistory()

  return (
    <Autocomplete
      openOnFocus={true}
      getSources={({ query }) => {
        return [
          {
            sourceId: 'algolia',
            getItemUrl: getItemUrl, //for keyboard support
            getItems: () => {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'boxers',
                    query,
                    params: {
                      hitsPerPage: 10,
                    },
                  },
                  {
                    indexName: 'events',
                    query,
                    params: {
                      hitsPerPage: 10,
                    },
                  },
                ],
              })
            },

            templates: {
              item: ({ item }) => {
                switch (getIndex(item)) {
                  case 'boxers':
                    return (
                      <BoxerHit
                        hit={item}
                        onClick={() => {
                          //history.push("/home")
                          window.location = getItemUrl(item)
                        }}
                      />
                    )
                  case 'events':
                    return (
                      <EventHit
                        hit={item}
                        onClick={() => {
                          // history.push("/home")
                          window.location = getItemUrl(item)
                        }}
                      />
                    )
                  default:
                    return
                }
              },
              noResults: () => {
                return 'No results for this query.'
              },
            },
          },
        ]
      }}
      navigator={{
        navigate({ itemUrl }) {
          if (itemUrl) {
            history.push(itemUrl)
          } else {
            console.log('no url found')
          }
        },
      }}
    />
  )
}

export default HeaderSearch
