import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import styles from './Contact.module.scss'

class Schedule extends React.Component {
  render() {
    return (
      <Row className={styles.row}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={styles.container}>
            <h3>Coming soon...</h3>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Schedule
