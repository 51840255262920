import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import queryString from 'query-string'
import Loader from '../Components/Loader'
import api from '../helpers/api'
import { ReactComponent as NotFoundIcon } from '../icons/search-remove.svg'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import styles from './Search.module.scss'
import Button from '../Components/Button'
import { Link } from 'react-router-dom'
import EventsTable from '../Components/EventsTable'
import Input from '../Components/Input'
import { ReactComponent as BinIcon } from '../icons/bin.svg'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import cx from 'classnames'
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg'

const Events = ({ loggedIn }) => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLast, setIsLast] = useState(false)
  const { search } = useLocation()
  const [parsedQuery, setParsedQuery] = useState(queryString.parse(search))
  const [filter, setFilter] = useState({
    name: '',
    country: 'Australia',
    state: '',
  })

  const setFilterValue = (field, value) => {
    setFilter({
      ...filter,
      [field]: value,
    })
  }

  useEffect(() => {
    setIsLoading(true)
    const query = {
      sort: 'date,DESC',
      size: 20,
      page: currentPage - 1,
      ...parsedQuery,
    }
    api
      .get(`events?${queryString.stringify(query)}`)
      .then((result) => {
        setRows(result.data.content)
        setIsLast(result.data.last)
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, parsedQuery])

  const hasSearchFilter =
    [parsedQuery.name, parsedQuery.country, parsedQuery.state].some(
      (property) => !!property
    ) && !isLoading

  return (
    <Row className={styles.row}>
      <Col xs={12} sm={12} md={3} lg={3}>
        <div className={styles.form}>
          <h2>Filter Results</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              setParsedQuery(pickBy(filter, identity))
            }}
          >
            <Input
              type="text"
              id="name"
              label="Event Name"
              onChange={(e) => setFilterValue('name', e.target.value)}
              autoComplete="off"
              data-lpignore="true"
              value={filter.name}
            />

            <Input
              type="country"
              id="country"
              label="Country"
              onChange={(value) =>
                setFilterValue('country', value.target.value)
              }
              autoComplete="off"
              data-lpignore="true"
              value={filter.country}
            />

            <Input
              type="state"
              label="State/region"
              autoComplete="off"
              data-lpignore="true"
              onChange={(value) => setFilterValue('state', value.target.value)}
              value={filter.state}
              country={filter.country}
            />

            <Button
              isSubmit={true}
              message={
                hasSearchFilter ? (
                  <span
                    className={styles.clear}
                    onClick={() => {
                      setFilter({
                        name: '',
                        country: 'Australia',
                        state: '',
                      })
                      setParsedQuery(queryString.parse(search))
                    }}
                  >
                    <BinIcon />
                    Clear Filter
                  </span>
                ) : null
              }
            >
              <SearchIcon />
              Filter
            </Button>
          </form>
        </div>
      </Col>
      <Col xs={12} sm={12} md={9} lg={9}>
        <div className={styles.results}>
          <div className={styles.top}>
            <h2>Results</h2>
            {loggedIn && <Link to="/admin/events/create">Create Event</Link>}
          </div>
          {!isLoading ? (
            rows.length > 0 ? (
              <>
                <EventsTable events={rows} />
                <div className={styles.pagination}>
                  <button
                    className={cx({
                      [styles.prev]: true,
                      [styles.disabled]: currentPage === 1,
                    })}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <ArrowLeft />
                    Previous
                  </button>
                  <button
                    className={cx({
                      [styles.next]: true,
                      [styles.disabled]: isLast,
                    })}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                    <ArrowRight />
                  </button>
                </div>
              </>
            ) : (
              <div className={styles.notfound}>
                <NotFoundIcon />
                <span>Nothing here matches your search</span>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Events
