import React, { useState, useEffect } from 'react'
import './styles/core.scss'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Main from './Components/Main'
import { Grid } from 'react-flexbox-grid'
import typography from './helpers/typography'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import localStorage from 'local-storage'
import api from './helpers/api'
import { get } from 'lodash'
import ReactGA from 'react-ga'
import { createTheme, ThemeProvider } from '@mui/material/styles'

typography.injectStyles()

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#7b9ea8',
    },
  },
})

function App() {
  ReactGA.initialize('UA-192979705-1')

  let location = useLocation()
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }, [location])

  const [loggedIn, setLoggedFlag] = useState(false)
  const [token, setToken] = useState(null)
  const [loginError, setLoginError] = useState('')
  const [loggingIn, setLoggingIn] = useState(false)
  const [initialised, setInitialised] = useState(false)

  function setLoggedIn(state, token, cb) {
    setLoggedFlag(state)
    setToken(token)
    setInitialised(true)
    cb()
  }

  function login(email, password) {
    // clear login error message
    setLoginError('')
    setLoggingIn(true)

    api
      .post(`login`, {
        email,
        password,
      })
      .then((response) => {
        setLoggedIn(true, response.headers.authorization, () => {
          localStorage.set(
            'auth',
            JSON.stringify({
              loggedIn: true,
              token: response.headers.authorization,
            })
          )
        })
      })
      .catch((error) => {
        setLoginError(get(error, 'response.data.message'))
      })
      .finally(() => {
        setLoggingIn(false)
      })
  }

  function logout() {
    setLoggedIn(false, null, () => {
      localStorage.remove('auth')
    })
  }

  function hydrateSession() {
    const savedAuthState = localStorage.get('auth')

    try {
      const authState = JSON.parse(savedAuthState)
      if (authState.loggedIn && authState.token) {
        setLoggedIn(authState.loggedIn, authState.token, () => {
          setInitialised(true)
        })
      }
    } catch (e) {
      // do nothing if we could not parse it
    }
  }

  useEffect(() => {
    hydrateSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Boxing Book</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header loggedIn={loggedIn} />
      <div style={{ paddingBottom: 64 }}>
        <Grid>
          <Main
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
            login={login}
            logout={logout}
            loginError={loginError}
            loggingIn={loggingIn}
            token={token}
            initialised={initialised}
          />
        </Grid>
      </div>
      <Footer />
    </ThemeProvider>
  )
}

export default App
