import React from 'react'
import cx from 'classnames'
import { ReactComponent as LoaderIcon } from '../icons/loading-circle-1.svg'
import styles from './Input.module.scss'

const Button = ({
  message,
  children,
  isLoading,
  isSubmit,
  type = 'secondary',
  ...rest
}) => {
  return (
    <div
      className={cx(styles.group, {
        [styles.loading]: isLoading,
        [styles.primary]: type === 'primary',
      })}
    >
      <button
        {...rest}
        disabled={isLoading}
        type={isSubmit ? 'submit' : 'button'}
      >
        {isLoading ? (
          <span className={styles.spinner}>
            <LoaderIcon />
          </span>
        ) : (
          children
        )}
      </button>
      {message}
    </div>
  )
}

export default Button
