import React, { useEffect, useState } from 'react'
import styles from './CreateFight.module.scss'
import Input from '../../Components/Input'
import Button from '../../Components/Button'
import api from '../../helpers/api'
import Loader from '../../Components/Loader'
import chrono from 'chrono-node'
import CreateFighter from '../../Components/Modals/CreateFighter'
import moment from 'moment'

function EditFight({ location, token, match, history }) {
  const { inputFight } = location
  const { id } = match.params
  const [fight, setFight] = useState(inputFight)
  const [fighterOne, setFighterOne] = useState(
    inputFight ? inputFight.fighters[0] : null
  )
  const [fighterTwo, setFighterTwo] = useState(
    inputFight ? inputFight.fighters[1] : null
  )
  const [loading, setLoading] = useState(false)
  const [modelOpen, setModelOpen] = useState(false)
  const [creatingSuggestionFighter, setCreatingSuggestionFighter] = useState(
    null
  )

  useEffect(() => {
    if (fight === undefined) {
      setLoading(true)
      api
        .get(`fights/${id}`)
        .then((f) => {
          const fightData = { ...f.data, weight: f.data.fighters[0].weight }
          setFight(fightData)
          setFighterOne(f.data.fighters[0])
          setFighterTwo(f.data.fighters[1])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [fight, id, token])

  function submitRow() {
    setLoading(true)

    api
      .put(
        `/auth/fights/${fight.id}`,
        {
          result: fight.result ? fight.result : 'UNK',
          location: fight.location,
          tournament: fight.tournament,
          verified: fight.verified,
          date: moment.utc(fight.date),
          notes: fight.notes,
          rounds: fight.rounds,
          roundLength: fight.roundLength,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // Fight was created successfully let's attach fighters
        if (response.status === 200) {
          const fightId = fight.id
          api
            .post(`/auth/fights/${fightId}/`, [fighterOne, fighterTwo], {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setFight(null)
                setFighterTwo(null)
                setFighterOne(null)
                history.goBack()
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }
      })
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2>Edit Fight</h2>
        {loading ? (
          <Loader />
        ) : fight && fighterOne && fighterTwo ? (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                submitRow(fight)
              }}
            >
              <Input
                id="date"
                label="Date"
                type="date"
                onChange={(value) =>
                  setFight({ ...fight, date: new Date(value).toDateString() })
                }
                value={chrono.en_GB.parseDate(fight.date)}
              />

              <Input
                id="result"
                label="Result"
                type="text"
                onChange={(e) => setFight({ ...fight, result: e.target.value })}
                value={fight.result}
              />

              <Input
                id="weight"
                label="Weight"
                type="text"
                onChange={(e) => {
                  setFight({ ...fight, weight: e.target.value })
                  setFighterOne({ ...fighterOne, weight: e.target.value })
                  setFighterTwo({ ...fighterTwo, weight: e.target.value })
                }}
                value={fight.weight}
              />

              <Input
                id="rounds"
                label="Rounds"
                type="text"
                onChange={(e) => setFight({ ...fight, rounds: e.target.value })}
                value={fight.rounds}
              />

              <Input
                id="roundLength"
                label="Round Length"
                type="text"
                onChange={(e) =>
                  setFight({ ...fight, roundLength: e.target.value })
                }
                value={fight.roundLength}
              />

              <Input
                id="location"
                label="Location"
                type="text"
                onChange={(e) =>
                  setFight({ ...fight, location: e.target.value })
                }
                value={fight.location}
              />

              <Input
                id="tournament"
                label="Tournament"
                type="text"
                onChange={(e) =>
                  setFight({ ...fight, tournament: e.target.value })
                }
                value={fight.tournament}
              />

              <Input
                id="notes"
                label="Notes"
                type="textarea"
                onChange={(e) => setFight({ ...fight, notes: e.target.value })}
                value={fight.notes}
              />

              <Input
                id="winner"
                label="Winner"
                type="select"
                onChange={(e) => {
                  if (e.target.value === 'one') {
                    setFighterOne({ ...fighterOne, winner: true })
                    setFighterTwo({ ...fighterTwo, winner: false })
                  } else {
                    setFighterOne({ ...fighterOne, winner: false })
                    setFighterTwo({ ...fighterTwo, winner: true })
                  }
                }}
                value={fighterOne.winner ? 'one' : 'two'}
                options={[
                  { key: 'one', value: 'Fighter 1' },
                  { key: 'two', value: 'Fighter 2' },
                ]}
                disabled={fight.result === 'D'}
              />

              <Input
                id="verified"
                label="Verified"
                type="checkbox"
                onChange={() =>
                  setFight({ ...fight, verified: !fight.verified })
                }
                value={fight.verified}
              />

              <h4>Fighter 1</h4>
              <div className={styles.fighterGroup}>
                <Input
                  className={styles.longInput}
                  disabled={true}
                  id="fighterOneName"
                  label="Boxer Name"
                  type="text"
                  value={
                    fighterOne.boxer
                      ? `${fighterOne.boxer.firstName} ${fighterOne.boxer.lastName}`
                      : fighterOne.name
                  }
                />
                <Button
                  onClick={() => {
                    setCreatingSuggestionFighter('one')
                    setModelOpen(true)
                  }}
                  type="primary"
                >
                  {fighterOne.boxer ? 'Edit' : 'Add'}
                </Button>
              </div>

              <h4>Fighter 2</h4>
              <div className={styles.fighterGroup}>
                <Input
                  className={styles.longInput}
                  disabled={true}
                  id="fighterTwoName"
                  label="Boxer Name"
                  type="text"
                  value={
                    fighterTwo.boxer
                      ? `${fighterTwo.boxer.firstName} ${fighterTwo.boxer.lastName}`
                      : fighterTwo.name
                  }
                />
                <Button
                  onClick={() => {
                    setCreatingSuggestionFighter('two')
                    setModelOpen(true)
                  }}
                  type="primary"
                >
                  {fighterTwo.boxer ? 'Edit' : 'Add'}
                </Button>
              </div>
              <Button isSubmit>Submit Fight</Button>
            </form>
            <hr />
            <CreateFighter
              token={token}
              isOpen={modelOpen}
              fighterId={
                creatingSuggestionFighter === 'one'
                  ? fighterOne.boxer
                    ? fighterOne.boxer.id
                    : null
                  : fighterTwo.boxer
                  ? fighterTwo.boxer.id
                  : null
              }
              onClose={() => setModelOpen(false)}
              onCreated={(boxer) => {
                creatingSuggestionFighter === 'one'
                  ? setFighterOne({
                      ...fighterOne,
                      boxer: boxer,
                      name: `${capitalizeFirstLetter(
                        boxer.firstName
                      )} ${capitalizeFirstLetter(boxer.lastName)}`,
                    })
                  : setFighterTwo({
                      ...fighterTwo,
                      boxer: boxer,
                      name: `${capitalizeFirstLetter(
                        boxer.firstName
                      )} ${capitalizeFirstLetter(boxer.lastName)}`,
                    })
                setModelOpen(false)
              }}
            />
          </>
        ) : (
          !loading && 'Cannot load fight'
        )}
      </div>
    </div>
  )
}

export default EditFight
