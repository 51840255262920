import React from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import moment from 'moment'
import { Link } from 'react-router-dom'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from '../Pages/Profile.module.scss'

class EventsTable extends React.Component {
  render() {
    const { events } = this.props

    return (
      <div className={styles.timeline}>
        <Table>
          <Thead>
            <Tr>
              <Th>Event</Th>
              <th>Country</th>
              <th>State/Region</th>
              <th>Date</th>
            </Tr>
          </Thead>
          <Tbody>
            {events.map((event) => {
              return (
                <Tr className={styles.table} key={`fighter-${event.id}`}>
                  <Td>
                    <Link to={`/events/${event.id}`}>{event.name}</Link>
                  </Td>
                  <Td>{event.country}</Td>
                  <Td>{event.state}</Td>
                  <Td>{moment.utc(event.date).format('dddd, MMMM Do YYYY')}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>
    )
  }
}

export default EventsTable
