import moment from 'moment'

export const getAge = (DOB) => {
  if (!DOB) {
    return '-'
  }

  return moment().diff(moment.utc(DOB), 'years')
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export function splitToChunks(array, parts) {
  let result = []
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}
