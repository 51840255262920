import React from 'react'
import cx from 'classnames'
import styles from './Input.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { CountryRegionData } from 'react-country-region-selector'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import _ from 'lodash'

function Input({
  id,
  label,
  onChange,
  hasError = false,
  type,
  value,
  disabled,
  country,
  options = [],
  min,
  max,
  shortCountry = false,
  valueText,
  className: classNameProp,
  handleBlur,
  ...rest
}) {
  const getCountries = React.useCallback(() => {
    return [['All', 'All']]
      .concat(CountryRegionData)
      .map(([countryName, countrySlug]) => (
        <MenuItem
          value={shortCountry ? countrySlug : countryName}
          key={countrySlug}
        >
          {!disabled ? (shortCountry ? countrySlug : countryName) : 'None'}
        </MenuItem>
      ))
  }, [disabled, shortCountry])

  const getRegions = React.useCallback(
    (country, countries) => {
      if (!country || country === 'All') {
        return []
      }

      const searchIndex = !shortCountry ? 0 : 1

      let selectedCountry = null

      countries.forEach((row) => {
        if (row[searchIndex] === country) {
          selectedCountry = row
        }
      })

      return selectedCountry[2].split('|').map((option) => option.split('~'))
    },
    [shortCountry]
  )

  function getRegionList() {
    return getRegions(country, CountryRegionData).map(
      ([regionName, regionShortCode]) => {
        const label = !shortCountry ? regionName : regionShortCode
        const value = !shortCountry ? regionName : regionShortCode
        return (
          <MenuItem key={regionName} value={value}>
            {!disabled ? label : 'None'}
          </MenuItem>
        )
      }
    )
  }

  if (type === 'date') {
    return (
      <div className={cx(styles.groupDate)}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            id={id}
            label={label}
            value={value}
            inputFormat="DD/MM/yyyy"
            onChange={(date) => onChange(date)}
            renderInput={(params) => <TextField {...params} />}
            error={hasError}
          />
        </LocalizationProvider>
      </div>
    )
  }
  if (type === 'select') {
    return (
      <div className={cx(styles.groupSelect, classNameProp)}>
        <FormControl fullWidth>
          <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
          <Select
            labelId={`${label}-select-label`}
            id={id}
            value={value}
            label={label}
            onChange={(e) => onChange(e)}
          >
            {options.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {!disabled ? option.value : 'None'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }
  if (type === 'slider') {
    const updateValue = _.debounce(
      (event, newValue, activeThumb) => onChange(event, newValue, activeThumb),
      5
    )

    return (
      <div className={cx(styles.groupSlider, classNameProp)}>
        <FormControl fullWidth>
          <FormLabel id={`${label}-select-label`}>{label}</FormLabel>
          <Grid container spacing={2} alignItems="center">
            <Grid sx={{ minWidth: '32px' }} item>
              {value[0]}
            </Grid>
            <Grid item xs>
              <Slider
                getAriaLabel={() => label}
                value={value}
                onChange={updateValue}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
                disableSwap
                min={min}
                max={max}
                disabled={disabled}
              />
            </Grid>
            <Grid sx={{ minWidth: '40px' }} item>
              {value[1] === max ? `${max}+` : value[1]}
            </Grid>
          </Grid>
        </FormControl>
      </div>
    )
  }

  if (type === 'country') {
    return (
      <div className={cx(styles.groupSelect, classNameProp)}>
        <FormControl fullWidth>
          <InputLabel id={`country-select-label`}>Country</InputLabel>
          <Select
            labelId={`country-select-label`}
            id={id}
            value={value}
            label={label}
            onChange={(e) => onChange(e)}
          >
            {getCountries()}
          </Select>
        </FormControl>
      </div>
    )
  }

  if (type === 'state') {
    return (
      <div className={cx(styles.groupSelect)}>
        <FormControl fullWidth>
          <InputLabel id={`state-select-label`}>State/region</InputLabel>
          <Select
            labelId={`state-select-label`}
            id={id}
            value={value}
            label={label}
            onChange={(e) => onChange(e)}
            disabled={country === 'All'}
          >
            {getRegionList()}
          </Select>
        </FormControl>
      </div>
    )
  }

  if (type === 'checkbox') {
    return (
      <div className={cx(styles.groupCheckbox)}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label={label}
          />
        </FormControl>
      </div>
    )
  }

  if (type === 'textarea') {
    return (
      <div className={cx(styles.group, { [styles.withError]: hasError })}>
        <TextField
          id={id}
          label={label}
          multiline
          value={value}
          onChange={(e) => onChange(e)}
          error={hasError}
          {...rest}
        />
      </div>
    )
  }

  if (type === 'radio') {
    return (
      <div className={cx(styles.groupRadio, { [styles.withError]: hasError })}>
        <FormControl fullWidth>
          <FormLabel id={`${label}-radio-buttons-group-label`}>
            {label}
          </FormLabel>
          <RadioGroup
            aria-labelledby={`${label}-radio-buttons-group-label`}
            defaultValue="female"
            name="radio-buttons-group"
          >
            {options.map((option) => (
              <FormControlLabel
                key={`${id}-${option.key}`}
                label={option.value}
                checked={value === option.key}
                onChange={(e) => onChange(e)}
                control={<Radio />}
                id={`${id}-${option.key}`}
                value={option.key}
                {...rest}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  return (
    <div
      className={cx(
        styles.group,
        { [styles.withError]: hasError, [styles.noMargin]: !label },
        classNameProp
      )}
    >
      <TextField
        error={hasError}
        label={label}
        disabled={disabled}
        type={type}
        value={value}
        id={id}
        variant="outlined"
        onChange={(e) => onChange(e)}
        {...rest}
      />
    </div>
  )
}

export default Input
