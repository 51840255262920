import React from 'react'
import Modal from 'react-responsive-modal'
import CreateBoxer from '../../Pages/Admin/CreateBoxer'
import styles from './Modal.module.scss'

class CreateFighter extends React.Component {
  render() {
    const {
      isOpen,
      onClose,
      onCreated,
      token,
      fighterId,
      isEdit,
      initialState,
    } = this.props
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        center
        classNames={{
          modal: styles.modal,
          overlay: styles.overlay,
        }}
      >
        <CreateBoxer
          initialState={initialState}
          isEdit={fighterId ? true : isEdit}
          onCreated={onCreated}
          token={token}
          fighterId={fighterId}
        />
      </Modal>
    )
  }
}

export default CreateFighter
