import React from 'react'
import styles from './Footer.module.scss'
import { Link } from 'react-router-dom'
import { FaInstagram } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'

class Footer extends React.Component {
  render() {
    return (
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <div className={styles.footerText}>
            <p className={styles.slogan}>Purpose Powered. Technology Driven.</p>
            © Boxing Book Pty Ltd. 2022 <Link to={'/terms'}>T&Cs</Link>{' '}
            <Link to={'/privacy'}>Privacy Policy</Link>
          </div>
          <div className={styles.footerIcons}>
            <a href={'https://www.instagram.com/theboxingbook/'}>
              <FaInstagram style={{ fontSize: '1.5rem', color: '#f7f7ff' }} />
            </a>
          </div>
          <div className={styles.footerIcons}>
            <Link to={'/contact'}>
              <HiOutlineMail style={{ fontSize: '1.5rem', color: '#f7f7ff' }} />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
