import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from '../Components/PrivateRoute'
import Home from '../Pages/Home'
import Profile from '../Pages/Profile'
import Search from '../Pages/Search'
import Login from '../Pages/Login'
import Logout from '../Pages/Logout'
import CreateBoxer from '../Pages/Admin/CreateBoxer'
import CreateFight from '../Pages/Admin/CreateFight'
import EditFight from '../Pages/Admin/EditFight'
import Contact from '../Pages/Contact'
import Champions from '../Pages/Champions'
import Schedule from '../Pages/Schedule'
import Terms from '../Pages/Terms'
import Privacy from '../Pages/Privacy'
import styles from './Main.module.scss'
import Events from '../Pages/Events'
import CreateEvent from '../Pages/Admin/CreateEvent'
import Event from '../Pages/Event'

class Main extends React.Component {
  render() {
    return (
      <div className={styles.main}>
        <Route exact path="/" component={Home} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route
          exact
          path="/fighter/:id"
          render={(props) => {
            return (
              <Profile key={props.match.params.id} {...props} {...this.props} />
            )
          }}
        />
        <Route
          exact
          path="/search"
          render={(routeProps) => {
            return <Search {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/events"
          render={(routeProps) => {
            return <Events {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/events/:id"
          render={(routeProps) => {
            return <Event {...routeProps} {...this.props} />
          }}
        />
        <PrivateRoute
          exact
          path="/admin/events/create"
          component={CreateEvent}
          props={this.props}
        />
        <Route
          exact
          path="/login"
          render={(routeProps) => {
            return <Login {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/logout"
          render={(routeProps) => {
            return <Logout {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/contact"
          render={(routeProps) => {
            return <Contact {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/champions"
          render={(routeProps) => {
            return <Champions {...routeProps} {...this.props} />
          }}
        />
        <Route
          exact
          path="/schedule"
          render={(routeProps) => {
            return <Schedule {...routeProps} {...this.props} />
          }}
        />
        <PrivateRoute
          exact
          path="/admin/fight/create"
          component={CreateFight}
          props={this.props}
        />
        <PrivateRoute
          exact
          path="/admin/fight/edit/:id"
          component={EditFight}
          props={this.props}
        />
        <PrivateRoute
          exact
          path="/admin/boxer/create"
          component={CreateBoxer}
          props={this.props}
        />
        <PrivateRoute
          exact
          path="/admin/boxer/edit/:id"
          component={CreateBoxer}
          props={{ ...this.props, isEdit: true }}
        />
        <PrivateRoute
          exact
          path="/admin/events/edit/:id"
          component={CreateEvent}
          props={{ ...this.props, isEdit: true }}
        />
      </div>
    )
  }
}

export default Main
