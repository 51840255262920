import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ props: routeProps, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return routeProps.initialised ? (
          routeProps.loggedIn ? (
            <Component {...props} {...routeProps} />
          ) : (
            <Redirect to="/login" />
          )
        ) : null
      }}
    />
  )
}

export default PrivateRoute
