import { autocomplete } from '@algolia/autocomplete-js'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'
import { withRouter } from 'react-router-dom'

/**
 * Mounts the auto complete widget element
 * https://www.algolia.com/doc/ui-libraries/autocomplete/guides/using-react/
 */
function Autocomplete(props) {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete({
      detachedMediaQuery: 'none',
      container: containerRef.current,
      onSubmit(params) {
        props.history.push('/search', {
          data: params.state.collections[0]?.items,
        })
      },
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root)
      },
      panelPlacement: 'end',
      // panelPlacement: "full-width",
      openOnFocus: false,
      ...props,
    })

    return () => {
      search.destroy()
    }
  }, [props])

  return <div ref={containerRef} />
}

export default withRouter(Autocomplete)
