import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import styles from './Contact.module.scss'

class Privacy extends React.Component {
  render() {
    return (
      <Row className={styles.row}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={styles.container}>
            <h1>Privacy Policy</h1>
            <p>
              <span>In this Privacy Policy:</span>
            </p>
            <ul>
              <li>
                <strong>
                  <span>&apos;us&apos;</span>
                </strong>
                <span>
                  &nbsp;<strong>&apos;we&apos;</strong> or{' '}
                  <strong>&apos;our&apos;</strong> means The Boxing Book Pty
                  Ltd. (ACN 639500860); and
                </span>
              </li>
              <li>
                <span>
                  &lsquo;<strong>our website</strong>&rsquo; means the Boxing
                  Book website at <strong>boxingbook.com</strong>.
                </span>
              </li>
            </ul>
            <p>
              <strong>
                <span>Our commitment to privacy and data protection</span>
              </strong>
            </p>
            <p>
              <span>
                We are committed to respecting your privacy and complying with
                our obligations under the Privacy Act 1988 (Cth) and other
                applicable privacy and data protection laws.&nbsp;
              </span>
            </p>
            <p>
              <span>
                We comply with the Australian Privacy Principles contained in
                the Privacy Act. The Australian Privacy Principles govern the
                way in which we collect, use, disclose, store, secure and
                dispose of your personal information. &nbsp;You can view the
                APPs on the website of The Office of the Australian Information
                Commissioner at&nbsp;
              </span>
              <a href="http://www.oaic.gov.au/">
                <span>www.oaic.gov.au</span>
              </a>
              <span>.</span>
            </p>
            <p>
              <span>
                This Privacy Policy sets out how we collect, use, store and
                disclose your personal information in accordance with the
                Australian Privacy Principles.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>
                  Consent to collection, use and disclosure of personal
                  information&nbsp;
                </span>
              </strong>
            </p>
            <p>
              <span>
                By providing personal information to us, you consent to our
                collection, use and disclosure of your personal information in
                accordance with this Privacy Policy and any other arrangements
                that apply between us. &nbsp; If you do not agree with the
                provisions of this Privacy Policy then you should not provide us
                with your personal information and/or you should notify us which
                provision you do not agree to.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Changes to this Privacy Policy</span>
              </strong>
            </p>
            <p>
              <span>
                We may change this Privacy Policy from time to time by
                publishing changes to it on our website. We encourage you to
                check our website periodically to ensure that you are aware of
                our current Privacy Policy.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span>What is personal information?</span>
              </strong>
            </p>
            <p>
              <span>
                Personal information includes information or an opinion about an
                individual who is either identified or reasonably identifiable.
                For example, this may include your name, age, gender, postcode
                and contact details.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>What personal information do we collect?</span>
              </strong>
            </p>
            <p>
              <span>
                We may collect the following types of personal information:
              </span>
            </p>
            <ul type="disc">
              <li>
                <span>name;</span>
              </li>
              <li>
                <span>age or date of birth;</span>
              </li>
              <li>
                <span>postcode;</span>
              </li>
              <li>
                <span>email address;</span>
              </li>
              <li>
                <span>boxing record;</span>
              </li>
              <li>
                <span>registration information;</span>
              </li>
              <li>
                <span>boxing club association;</span>
              </li>
              <li>
                <span>[Health and fitness];</span>
              </li>
              <li>
                <span>
                  Competitive boxing history, including an itemised list of all
                  boxing events you participated in. As well as information
                  specific to these events, such as; date, location, weight
                  division, name of opponents and their competitive record,
                  official fight result and length;
                </span>
              </li>
              <li>
                <span>
                  your device ID, device type, geo-location information,
                  computer and connection information, statistics on page views,
                  traffic to and from the sites, ad data, IP address and
                  standard web log information;
                </span>
              </li>
              <li>
                <span>
                  details of the products and services we have provided to you
                  or that you have enquired about, including any additional
                  information necessary to deliver those products and services
                  and respond to your enquiries;
                </span>
              </li>
              <li>
                <span>
                  information you provide to us through customer surveys; or
                </span>
              </li>
              <li>
                <span>any additional personal information:</span>
                <ul type="circle">
                  <li>
                    <span>
                      that you provide to us directly through our website or
                      indirectly through your use of our website or through
                      other websites or accounts from which you permit us to
                      collect information; or&nbsp;
                    </span>
                  </li>
                  <li>
                    <span>
                      which may be required in order to facilitate your dealings
                      with us.
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>
                We may collect these types of personal information either
                directly from you, or from third parties. We may collect this
                information when you:
              </span>
            </p>
            <ul type="disc">
              <li>
                <span>provide us with the information directly;</span>
              </li>
              <li>
                <span>register on our website;</span>
              </li>
              <li>
                <span>
                  communicate with us through correspondence, chats, email, or
                  when you share information with us from other social
                  applications, services or websites; or
                </span>
              </li>
              <li>
                <span>
                  interact with our sites, services, content and advertising.
                </span>
              </li>
            </ul>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>
                  We do not purposefully collect sensitive information about you
                </span>
              </strong>
            </p>
            <p>
              <span>
                We do not collect sensitive information about you (as defined in
                the Privacy Act), unless you provide it to us voluntarily.
                &lsquo;Sensitive information&rsquo; includes information or
                opinion about an individual&rsquo;s racial or ethnic origin,
                political opinion, religious beliefs, sexual orientation or
                criminal record, provided that the information or opinion
                otherwise meets the definition of personal information.
              </span>
            </p>
            <p>
              <span>
                You consent to us collecting sensitive information which you
                provide to us voluntarily. If we need to collect any sensitive
                information for a specific purpose, we will ask for your
                specific consent.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Personal information collected from other sources</span>
              </strong>
            </p>
            <p>
              <span>
                If you provide us with personal information relating to any
                other individual, you warrant and represent that you have
                obtained the consent of such individual to provide us with such
                personal information for the purposes set out in this Privacy
                Policy.&nbsp;
              </span>
            </p>
            <p>
              <span>
                If someone other than you provides us with personal information
                about you that we did not ask for, or you provide us with
                unsolicited personal information, we will only hold, use or
                disclose this information if we determine that we could have
                collected this information from you had we asked for it.
                &nbsp;In this circumstance, we will take all reasonable steps to
                notify you of the collection of that information. &nbsp;If we
                could not have collected this personal information, we will
                lawfully de-identify or destroy that personal information.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>
                  Why do we collect, use and disclose your personal information?
                </span>
              </strong>
            </p>
            <p>
              <span>
                We may collect, hold, use and disclose your personal information
                for the following purposes:
              </span>
            </p>
            <ul type="disc">
              <li>
                <span>
                  to enable you to access and use our website or other services;
                </span>
              </li>
              <li>
                <span>
                  to operate, protect, improve and optimise our business and our
                  users&rsquo; experience, such as to perform analytics, improve
                  the information service we provide, conduct research and for
                  advertising and marketing;
                </span>
              </li>
              <li>
                <span>
                  to send you service, support and administrative messages,
                  reminders, technical notices, updates, security alerts, and
                  information requested by you;
                </span>
              </li>
              <li>
                <span>
                  to administer rewards, surveys, contests, or other promotional
                  activities or events sponsored or managed by us or our
                  business partners;
                </span>
              </li>
              <li>
                <span>
                  to comply with our legal obligations, resolve any disputes
                  that we may have with any of our users, and enforce our
                  agreements with third parties; and
                </span>
              </li>
              <li>
                <span>advertising and product promotion</span>
              </li>
            </ul>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>
                We may also disclose your personal information to a trusted
                third party who also holds other information about you. This
                third party may combine that information in order to enable it
                and us to develop anonymised consumer insights so that we can
                better understand your preferences and interests, personalise
                your experience and enhance the products and services that you
                receive.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>To whom do we disclose your personal information?</span>
              </strong>
            </p>
            <p>
              <span>
                We may disclose personal information for the purposes described
                in this Privacy Policy to:
              </span>
            </p>
            <ul type="disc">
              <li>
                <span>our employees;</span>
              </li>
              <li>
                <span>
                  third party suppliers and service providers (including
                  providers for the operation of our website and our business or
                  in connection with providing our services to you);
                </span>
              </li>
              <li>
                <span>
                  our professional advisers who have a need to know the
                  information for the advisory services that they provide us;
                </span>
              </li>
              <li>
                <span>
                  payment systems operators (eg merchants receiving card
                  payments);
                </span>
              </li>
              <li>
                <span>our agents, business partners or partners;</span>
              </li>
              <li>
                <span>
                  our sponsors or promoters of any competition that we conduct
                  via our services;
                </span>
              </li>
              <li>
                <span>
                  anyone to whom our business or a substantial part of our
                  business or assets are transferred;
                </span>
              </li>
              <li>
                <span>
                  specific third parties authorised by you to receive
                  information held by us; and/or
                </span>
              </li>
              <li>
                <span>
                  other persons, including government agencies, regulatory
                  bodies and law enforcement agencies, or as required,
                  authorised or permitted by law.
                </span>
              </li>
            </ul>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Direct Marketing</span>
              </strong>
            </p>
            <p>
              <span>
                We may, from time to time, send direct marketing communications
                to you about our business and services and other material that
                we consider you would find interesting or useful. If you do not
                wish to receive such direct marketing communications, you can
                always opt out. If you are receiving email communications from
                us, there will be a mechanism to opt out contained in each of
                those emails.
              </span>
            </p>
            <p>
              <span>
                If you choose to opt out of all direct marketing communications,
                please note that we may still contact you for other reasonable
                purposes, including information that we are legally required to
                send, notifications of changes to our services or policies and
                information regarding the use, rights, benefits or obligations
                of users of our services.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Security</span>
              </strong>
            </p>
            <p>
              <span>
                We take reasonable steps to protect your personal information
                from misuse, interference and loss, as well as unauthorised
                access, modification or disclosure. &nbsp;We use a number of
                physical, administrative, personnel and technical measures to
                protect your personal information. Despite these reasonable
                steps, no security system is impenetrable and, due to the
                inherent nature of the internet, we cannot guarantee that
                information, during transmission through the internet or while
                stored on our systems or otherwise, will be absolutely safe from
                unauthorised access by others.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>International transfers of personal information</span>
              </strong>
            </p>
            <p>
              <span>
                Some of the third parties we disclose your personal information
                to may be based or have servers located overseas. Before we
                disclose your information to overseas recipients, we will take
                all reasonable steps to ensure that your information is only
                processed for authorised purposes and adequately protected using
                the appropriate technical, organisational, contractual or other
                lawful means. You consent to us disclosing your personal
                information to overseas recipients on this basis.
              </span>
            </p>
            <p>
              <span>
                We are based in Australia and any information you provide us may
                be transferred from your country of origin to Australia. &nbsp;
              </span>
            </p>
            <p>
              <span>
                Your decision to provide personal information to us or allow us
                to collect such information constitutes your consent to the
                international transfer of the information described above.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Links</span>
              </strong>
            </p>
            <p>
              <span>
                Our website may contain links to websites operated by third
                parties. &nbsp; Unless expressly stated otherwise, we are not
                responsible for the privacy practices of, or any content on,
                those linked websites, and have no control over or rights in
                those linked websites. The privacy policies that apply to those
                other websites may differ substantially from this Privacy
                Policy. &nbsp;We encourage you to read the privacy policies of
                linked websites before using them.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>
                  How to exercise your rights in relation to your personal
                  information
                </span>
              </strong>
            </p>
            <p>
              <span>
                You have the following rights in relation to the personal
                information that you provide us:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  you can request access, correction, updates or deletion of
                  your personal information;
                </span>
              </li>
              <li>
                <span>
                  you can object to processing of your personal information, ask
                  us to restrict processing of your personal information or
                  request portability of your personal information;
                </span>
              </li>
              <li>
                <span>
                  if we have collected and process your personal information
                  with your consent, then you can withdraw your consent at any
                  time. Withdrawing your consent will not affect the lawfulness
                  of any processing we conducted prior to your withdrawal, nor
                  will it affect processing of your personal information
                  conducted in reliance on lawful processing grounds other than
                  consent; and
                </span>
              </li>
              <li>
                <span>
                  you have the right to complain to The Office of the Australian
                  Information Commissioner about our collection and use of your
                  personal information &ndash; see&nbsp;
                </span>
                <a href="http://www.oaic.gov.au/">
                  <span>www.oaic.gov.au</span>
                </a>
                <span>.</span>
              </li>
            </ul>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>
                To exercise any of these rights, please contact us at
                admin@boxingbook.com. &nbsp;We will respond to your request to
                change, correct, or delete your information within a reasonable
                timeframe and notify you of the action we have taken.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Accessing or correcting your personal information</span>
              </strong>
            </p>
            <p>
              <span>
                You can access the personal information we hold about you by
                contacting us at admin@boxingbook.com. Sometimes, we may not be
                able to provide you with access to all of your personal
                information and, where this is the case, we will tell you why.
                We may also need to verify your identity when you request your
                personal information.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>
                We will take all reasonable steps to ensure that the personal
                information we collect, use or disclose is accurate, complete
                and up-to-date, and we will try to confirm your details through
                our communications with you and promptly add updated or new
                personal information to existing records when we are advised.
                However, we rely on the accuracy of personal information as
                provided to us both directly and indirectly. &nbsp;If you think
                that any personal information we hold about you is inaccurate,
                please contact us at&nbsp;
              </span>
              <a href="mailto:admin@boxingbook.com">
                <span>admin@boxingbook.com</span>
              </a>
              <span>
                &nbsp;and we will take reasonable steps to ensure that it is
                corrected.
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>How long do we keep your personal information?</span>
              </strong>
            </p>
            <p>
              <span>
                We retain personal information that you provide to us where we
                have an ongoing legitimate business need to do so (for example,
                as needed to comply with our legal obligations, resolve disputes
                and enforce our agreements).
              </span>
            </p>
            <p>
              <span>
                When we have no ongoing legitimate business need to process your
                personal information, we securely delete the information or
                anonymise it or, if this is not possible, securely store your
                personal information and isolate it from any further processing
                until deletion is possible. We will delete this information at
                an earlier date if you so request, as described above.
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span>Cookies and similar technologies &nbsp;</span>
              </strong>
            </p>
            <p>
              <span>
                We may use cookies and similar technologies (such as web
                beacons) to analyse trends, administer our website, track
                users&rsquo; movements around our website, and to gather
                demographic information about our user base as a whole. &nbsp;A
                cookie in no way gives us access to your computer or any
                information about you, other than the data you choose to share
                with us. You can choose to accept or decline cookies. Most web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer.
                This may prevent you from taking full advantage of our website.
                Overall, cookies help us provide you with a better website by
                enabling us to monitor which pages you find useful and which you
                do not. &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Making a complaint</span>
              </strong>
            </p>
            <p>
              <span>
                If you think we have breached the Privacy Act, or you wish to
                make a complaint about the way we have handled your personal
                information, you can contact us at admin@boxingbook.com . Please
                include your name, email address and/or telephone number and
                clearly describe your complaint. We will acknowledge your
                complaint and respond to you regarding your complaint within a
                reasonable period of time. If you think that we have failed to
                resolve the complaint satisfactorily, you are entitled to lodge
                your complaint with the Office of the Australian Information
                Commissioner at&nbsp;
              </span>
              <a href="http://www.oaic.gov.au/">
                <span>www.oaic.gov.au</span>
              </a>
              <span>.</span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Contact Us</span>
              </strong>
            </p>
            <p>
              <span>
                For further information about how this Privacy Policy is applied
                or our privacy or data protection practices, or to access or
                correct your personal information, or make a complaint, please
                contact us using the details set out below:
              </span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>Privacy Officer: Reuben Staples</span>
            </p>
            <p>
              <span>Email: admin@boxingbook.com</span>
            </p>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Last amended:</span>
              </strong>
              <span>&nbsp;[22 March, 2022]</span>
            </p>
            <p>&nbsp;</p>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Privacy
