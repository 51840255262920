import Typography from 'typography'
import githubTheme from 'typography-theme-github'

githubTheme.headerColor = '#262626'

githubTheme.overrideThemeStyles = () => ({
  a: {
    color: '#7ea3cc',
    textDecoration: 'none',
  },
})

const typography = new Typography(githubTheme)

export default typography
