import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import cx from 'classnames'
import moment from 'moment'
import MediaQuery from 'react-responsive'
import api from '../helpers/api'
import { getAge } from '../helpers/utility'
import { Link } from 'react-router-dom'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Loader from '../Components/Loader'
import { ReactComponent as CalendarIcon } from '../icons/calendar-3.svg'
import { ReactComponent as PinIcon } from '../icons/pin.svg'
import { ReactComponent as BoxIcon } from '../icons/video-game-boxing.svg'
import { ReactComponent as TrophyIcon } from '../icons/award-trophy-person.svg'
import { ReactComponent as ScaleIcon } from '../icons/diet-scale.svg'
import { ReactComponent as BinIcon } from '../icons/bin.svg'
import { ReactComponent as TournamentIcon } from '../icons/trophy.svg'
import { ReactComponent as EditIcon } from '../icons/common-file-text-edit.svg'
import { ReactComponent as BoxerIcon } from '../icons/boxing-boxer.svg'
import { ReactComponent as WarningFlag } from '../icons/flag-warning.svg'
import _ from 'lodash'
import confirm from '../helpers/confirm'
import ReportFight from '../helpers/ReportFight'
import { capitalizeFirstLetter } from '../helpers/utility'
import queryString from 'query-string'
import countryLookUp from 'country-code-lookup'
import ReactCountryFlag from 'react-country-flag'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from './Profile.module.scss'
import Avatar from '../Components/Avatar'
import Button from '../Components/Button'
import MergeFighter from '../helpers/MergeFighter'
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg'
import Tooltip from '@mui/material/Tooltip'
import { default as ButtonNew } from '@mui/material/Button'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

function expandResult(result) {
  switch (result) {
    case 'UD':
      return 'Unanimous decision'
    case 'MD':
      return 'Majority decision'
    case 'SD':
      return 'Split decision'
    case 'KO':
      return 'Knockout'
    case 'TKO':
      return 'Technical knockout'
    case 'NC':
      return 'No contest'
    case 'D':
      return 'Draw'
    case 'DQ':
      return 'Disqualified'
    case 'EXH':
      return 'Exhibition'
    case 'PTS':
      return 'Points decision'
    case 'RTD':
      return 'Retired in corner'
    case 'RSC':
      return 'Referee stop contest'
    case 'RSC_I':
      return 'Referee stop contest injury'
    case 'WO':
      return 'Walk Over'
    default:
      return 'Unknown'
  }
}

function Profile({ match, token, loggedIn, history }) {
  const [profileLoaded, setProfileLoaded] = useState(false)
  const [fightsLoaded, setFightsLoaded] = useState(false)
  const [fighter, setFighter] = useState({})
  const [fights, setFights] = useState([])
  const [reportOpen, setReportOpen] = useState(false)
  const [mergeOpen, setMergeOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLast, setIsLast] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [fightId, setFightId] = useState(null)

  function confirmDelete(id) {
    confirm(() => {
      api
        .delete(`auth/boxers/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          history.push('/search')
        })
    }, 'You want to delete this boxer?')
  }

  function setFighterData(fighter) {
    setFighter(fighter)
    setProfileLoaded(true)
  }

  function setFightsData(fights) {
    if (fights.length > 0) {
      setFights(fights)
    }
    setFightsLoaded(true)
  }

  function recalculateWLD() {
    confirm(() => {
      api
        .put(`auth/boxers/${fighterId}/wld`, null, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        })
    }, 'Recalculate WLD?')
  }

  function updateHidden() {
    let path = fighter.hidden ? 'unhide' : 'hide'
    confirm(() => {
      api
        .put(`auth/boxers/${fighterId}/${path}`, null, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        })
    }, 'Update hidden?')
  }

  function deleteFight(id) {
    confirm(() => {
      api
        .delete(`auth/fights/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        })
    }, 'You want to delete this fight?')
  }

  const fighterId = match.params.id

  useEffect(() => {
    api.get(`boxers/${fighterId}`).then((result) => {
      setFighterData(result.data)
    })

    const query = {
      sort: 'date,DESC',
      size: 50,
      page: currentPage - 1,
    }

    api
      .get(`boxers/${fighterId}/fights?${queryString.stringify(query)}`)
      .then((result) => {
        setFightsData(result.data.content)
        setIsLast(result.data.last)
      })
  }, [fighterId, mergeOpen, currentPage])

  if (!profileLoaded) {
    return <Loader />
  }

  const country = countryLookUp.byCountry(fighter.country)

  const pictureUrl = fighter.imageUrl
    ? `https://res.cloudinary.com/dy1r097fd/image/upload//f_auto,q_auto/v1505636095/${fighter.imageUrl}`
    : null

  const name = `${capitalizeFirstLetter(
    fighter.firstName
  )} ${capitalizeFirstLetter(fighter.lastName)}`

  return (
    <Row className={styles.row}>
      <Col xs={12} sm={12} md={3} lg={3}>
        <div className={styles.profile}>
          <Avatar
            width="120px"
            height="120px"
            imageUrl={pictureUrl}
            gender={fighter.gender}
          />
          <div className={styles.name}>{name}</div>
          {fighter.nickName ? (
            <div className={styles.nickName}>{fighter.nickName}</div>
          ) : null}
          <div className={styles.division}>Weight: {fighter.weight}kg</div>
          {fighter.gender ? (
            <div className={styles.gender}>{fighter.gender}</div>
          ) : null}
          <div className={styles.stats}>
            <div className={styles.box}>
              <span className={styles.figure}>{fighter.stance || '-'}</span>
              <span className={styles.description}>stance</span>
            </div>
            <div className={styles.separator} />
            <div className={styles.box}>
              <span className={styles.figure}>
                {getAge(fighter.dateOfBirth)}
              </span>
              <span className={styles.description}>age</span>
            </div>
          </div>
          <div className={styles.status}>
            <span> Status: {fighter.active ? 'Active' : 'Not active'} </span>
            {fighter.active ? (
              <CheckCircleOutlineIcon sx={{ ml: 0.5 }} color="success" />
            ) : null}
          </div>
          <div className={styles.division}>Bouts: {fighter.fights ?? '-'}</div>
          <div className={styles.division}>
            Exhibitions: {fighter.exhibitions ?? '-'}
          </div>
          <div className={styles.division}>Rounds: {fighter.rounds ?? '-'}</div>
          <div className={styles.results}>
            <div className={cx(styles.result, styles.green)}>
              <span className={styles.number}>{fighter.wins}</span>
              <span className={styles.label}>wins</span>
            </div>
            <div className={cx(styles.result, styles.red)}>
              <span className={styles.number}>{fighter.losses}</span>
              <span className={styles.label}>losses</span>
            </div>
            <div className={cx(styles.result, styles.blue)}>
              <span className={styles.number}>{fighter.draws}</span>
              <span className={styles.label}>draws</span>
            </div>
          </div>
          <div className={styles.division}>
            Born: {fighter.birthCountry ?? '-'}
          </div>
          <div className={styles.division}>
            Country: {fighter.country}{' '}
            {country && country.iso2 ? (
              <ReactCountryFlag
                svg
                countryCode={country.iso2}
                className={styles.flag}
              />
            ) : (
              '-'
            )}
          </div>
          <div className={styles.division}>
            State/region: {fighter.state ?? '-'}
          </div>
          <div className={styles.actions}>
            {loggedIn && (
              <Button
                type={`secondary`}
                onClick={() => confirmDelete(fighterId)}
              >
                Delete Fighter
              </Button>
            )}
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={9} lg={9}>
        <div className={styles.fights}>
          <div className={styles.top}>
            <h2>Career</h2>
            <div className={styles.topAdmin}>
              {loggedIn && (
                <Link
                  className={styles.adminLink}
                  to={`/admin/boxer/edit/${fighterId}`}
                >
                  Edit Boxer
                </Link>
              )}
              {loggedIn && (
                <Link
                  to={'#'}
                  className={styles.adminLink}
                  onClick={() => setMergeOpen(true)}
                >
                  Merge Fighter
                </Link>
              )}
              {loggedIn && (
                <Link
                  to={'#'}
                  className={styles.adminLink}
                  onClick={() => recalculateWLD()}
                >
                  Recalculate WLD
                </Link>
              )}
              {loggedIn && (
                <Link
                  to={'#'}
                  className={styles.adminLink}
                  onClick={() => updateHidden()}
                >
                  {fighter.hidden ? 'Unhide' : 'Hide'}
                </Link>
              )}
            </div>
          </div>
          {!fightsLoaded ? (
            <Loader />
          ) : (
            <div className={styles.timeline}>
              <div className={styles.pagination}>
                <button
                  className={cx({
                    [styles.prev]: true,
                    [styles.disabled]: currentPage === 1,
                  })}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <ArrowLeft />
                  Previous
                </button>
                <div className={styles.pageNumber}>{currentPage}</div>
                <button
                  className={cx({
                    [styles.next]: true,
                    [styles.disabled]: isLast,
                  })}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                  <ArrowRight />
                </button>
              </div>
              <Table>
                <Thead>
                  <Tr>
                    {loggedIn ? <Th>Fight ID</Th> : <></>}
                    <Th>
                      <MediaQuery maxDeviceWidth={1224}>
                        <BoxerIcon />
                        Outcome
                      </MediaQuery>
                    </Th>
                    <Th>
                      <CalendarIcon /> Date
                    </Th>
                    <Th>
                      <ScaleIcon /> Weight
                    </Th>
                    <Th>
                      <BoxIcon /> Opponent
                    </Th>
                    <Th>
                      <TrophyIcon /> W-L-D
                    </Th>
                    <Th>
                      <TournamentIcon /> Tournament
                    </Th>
                    <Th>
                      <PinIcon /> Location
                    </Th>
                    <Th>
                      <WarningFlag /> Report
                    </Th>
                    {loggedIn ? <Th>Actions</Th> : null}
                  </Tr>
                </Thead>
                <Tbody>
                  {fightsLoaded && (
                    <>
                      {fights.map((fight) => {
                        const opponent = fight.fighters.find((fighter) => {
                          if (!fighter.boxer || !fighter.boxer.id) {
                            return true
                          }

                          return (
                            parseInt(fighter.boxer.id) !== parseInt(fighterId)
                          )
                        })

                        const opponentHasProfile = !!opponent.boxer
                        const fighterAtTheTime = fight.fighters.find(
                          (fighter) =>
                            parseInt(_.get(fighter, 'boxer.id')) ===
                            parseInt(fighterId)
                        )

                        const isWin = fighterAtTheTime.winner
                        const isDraw = fight.result === 'D'
                        const isWalkOver = fight.result === 'WO'
                        const isExhibition = fight.result === 'EXH'
                        const isUnknown = fight.result === 'UNK'

                        let fightResult = fight.result

                        if (!fightResult || isUnknown) {
                          fightResult = isWin ? 'win' : 'loss'
                        }

                        const result = fightResult.toUpperCase()
                        return (
                          <Tr key={fight.id}>
                            {loggedIn ? <Th>{fight.id}</Th> : <></>}
                            <Td className={styles.cell}>
                              <Tooltip
                                open={showTooltip && fightId === fight.id}
                                onOpen={() => {
                                  setShowTooltip(true)
                                  setFightId(fight.id)
                                }}
                                onClose={() => {
                                  setShowTooltip(false)
                                  setFightId(null)
                                }}
                                title={expandResult(result)}
                              >
                                <ButtonNew
                                  onClick={() => {
                                    setShowTooltip(!showTooltip)
                                    setFightId(
                                      fightId === fight.id ? null : fight.id
                                    )
                                  }}
                                  className={cx(styles.badge, {
                                    [styles.win]: isWin,
                                    [styles.loss]: !isWin && !isWalkOver,
                                    [styles.draw]:
                                      isDraw || (!isWin && isWalkOver),
                                    [styles.exh]: isExhibition,
                                  })}
                                >
                                  {result}
                                </ButtonNew>
                              </Tooltip>
                            </Td>
                            <Td className={styles.cell}>
                              {moment
                                .utc(fight.date, 'YYYY-MM-DD HH:mm:ss')
                                .format('DD/MM/YYYY')}
                            </Td>
                            <Td className={styles.cell}>
                              {fighterAtTheTime
                                ? `${fighterAtTheTime.weight} kg`
                                : '-'}
                            </Td>
                            <Td className={styles.cell}>
                              {opponentHasProfile ? (
                                <Link
                                  className={styles.opponent}
                                  to={`/fighter/${opponent.boxer.id}`}
                                >
                                  {capitalizeFirstLetter(
                                    opponent.boxer.firstName
                                  )}{' '}
                                  {capitalizeFirstLetter(
                                    opponent.boxer.lastName
                                  )}{' '}
                                  {opponent.weight
                                    ? `(${opponent.weight} kg)`
                                    : null}
                                </Link>
                              ) : (
                                <>
                                  {opponent.name}{' '}
                                  {opponent.weight
                                    ? `(${opponent.weight} kg)`
                                    : null}
                                </>
                              )}
                            </Td>
                            <Td className={styles.cell}>
                              {opponentHasProfile ? (
                                <div className={styles.resultsAtTheTime}>
                                  <div
                                    className={cx(styles.result, styles.green)}
                                  >
                                    <span className={styles.number}>
                                      {opponent.winsAtTime}
                                    </span>
                                  </div>
                                  <div
                                    className={cx(styles.result, styles.red)}
                                  >
                                    <span className={styles.number}>
                                      {opponent.lossesAtTime}
                                    </span>
                                  </div>
                                  <div
                                    className={cx(styles.result, styles.blue)}
                                  >
                                    <span className={styles.number}>
                                      {opponent.drawsAtTime}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                '-'
                              )}
                            </Td>
                            <Td className={styles.cell}>
                              <div>{fight.tournament}</div>
                            </Td>
                            <Td className={styles.cell}>
                              <div>{fight.location}</div>
                              <div className={styles.notes}>{fight.notes}</div>
                            </Td>
                            <Td className={styles.report}>
                              <button
                                onClick={() => {
                                  setReportOpen(true)
                                }}
                              >
                                <WarningFlag />
                              </button>
                            </Td>
                            {reportOpen ? (
                              <ReportFight
                                isOpen={reportOpen}
                                onClose={() => setReportOpen(false)}
                                fightId={fight.id}
                                fighterId={fighter.id}
                                fighterName={name}
                              />
                            ) : null}
                            {loggedIn ? (
                              <Td className={styles.admin}>
                                <Link
                                  to={{
                                    pathname: `/admin/fight/edit/${fight.id}`,
                                    inputFight: fight,
                                  }}
                                >
                                  <button>
                                    <EditIcon />
                                  </button>
                                </Link>

                                <button onClick={() => deleteFight(fight.id)}>
                                  <BinIcon />
                                </button>
                              </Td>
                            ) : null}
                          </Tr>
                        )
                      })}
                    </>
                  )}
                </Tbody>
              </Table>
              <div className={styles.pagination}>
                <button
                  className={cx({
                    [styles.prev]: true,
                    [styles.disabled]: currentPage === 1,
                  })}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <ArrowLeft />
                  Previous
                </button>
                <div className={styles.pageNumber}>{currentPage}</div>
                <button
                  className={cx({
                    [styles.next]: true,
                    [styles.disabled]: isLast,
                  })}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                  <ArrowRight />
                </button>
              </div>
              {mergeOpen ? (
                <MergeFighter
                  isOpen={mergeOpen}
                  onClose={() => setMergeOpen(false)}
                  fighterName={name}
                  fighterId={fighterId}
                  token={token}
                />
              ) : null}
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Profile
