import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { ReactComponent as CloseIcon } from '../icons/remove.svg'
import { ReactComponent as MenuIcon } from '../icons/navigation-menu.svg'
import { withRouter, Link, NavLink } from 'react-router-dom'
import logo from '../images/logo.png'
import styles from './Header.module.scss'
import HeaderSearch from './HeaderSearch'

class Header extends React.Component {
  state = {
    menuOpen: false,
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  render() {
    const { loggedIn } = this.props

    return (
      <div className={styles.headerContainer}>
        <Grid>
          <Row>
            <Col className={styles.noPadding} xs={12} sm={12} md={12} lg={12}>
              <div className={styles.header}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src={logo} alt={'Boxing Book'} />
                  </Link>
                </div>
                <nav
                  className={`${styles.navigation} ${
                    this.state.menuOpen ? styles.open : ''
                  }`}
                >
                  <CloseIcon
                    className={styles.closeMobile}
                    onClick={this.toggleMenu}
                  />
                  <ul>
                    <li>
                      <NavLink
                        exact={true}
                        activeClassName={styles.active}
                        to="/"
                        onClick={() => this.closeMenu()}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName={styles.active}
                        to="/search"
                        onClick={() => this.closeMenu()}
                      >
                        Fighters
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName={styles.active}
                        to="/events"
                        onClick={() => this.closeMenu()}
                      >
                        Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName={styles.active}
                        to="/champions"
                        onClick={() => this.closeMenu()}
                      >
                        Champions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName={styles.active}
                        to="/schedule"
                        onClick={() => this.closeMenu()}
                      >
                        Schedule
                      </NavLink>
                    </li>
                    {loggedIn ? (
                      <>
                        <li>
                          <NavLink
                            activeClassName={styles.active}
                            to="/admin/fight/create"
                            onClick={() => this.closeMenu()}
                          >
                            + Fight
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            activeClassName={styles.active}
                            to="/admin/boxer/create"
                            onClick={() => this.closeMenu()}
                          >
                            + Boxer
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <NavLink
                        activeClassName={styles.active}
                        to="/contact"
                        onClick={() => this.closeMenu()}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                <HeaderSearch />
                <MenuIcon
                  className={styles.hamburger}
                  onClick={this.toggleMenu}
                />
                {loggedIn ? (
                  <div className={styles.logout}>
                    <Link to="/logout">Sign out</Link>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(Header)
