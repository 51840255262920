import algoliasearch from 'algoliasearch/lite'

const isDev = [
  '127.0.0.1',
  'localhost',
  'am-box-rec-web-staging',
].some((host) => window.location.origin.includes(host))

let searchClient
if (isDev) {
  const appId = 'QYQQ6SCGIM'
  const apiKey = '3cb6aa2c1d06392f99a863bd9ef74837'
  searchClient = algoliasearch(appId, apiKey)
} else {
  const appId = 'VLQSHB5CHU'
  const apiKey = 'f8e3de8f164e166857456717200ed449'
  searchClient = algoliasearch(appId, apiKey)
}
export default searchClient
