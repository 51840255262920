import React from 'react'
import cx from 'classnames'
import styles from './Avatar.module.scss'
import femaleFace from '../images/female.png'
import maleFace from '../images/male.png'

const Avatar = ({
  imageUrl,
  height = '60px',
  width = '60px',
  className: classNameProp,
  gender = 'male',
}) => {
  const defaultFace = gender
    ? gender.toLowerCase() === 'male'
      ? maleFace
      : femaleFace
    : maleFace
  const url = imageUrl ? imageUrl : defaultFace

  return (
    <div
      className={cx(styles.picture, classNameProp)}
      style={{ backgroundImage: `url(${url})`, height, width }}
    />
  )
}

export default Avatar
