import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from '../Components/Modals/Modal.module.scss'
import Input from '../Components/Input'
import Button from '../Components/Button'

function ReportFight({ isOpen, onClose, fightId, fighterId, fighterName }) {
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)

  function updateInput(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  async function handleSubmit({ event, onClose }) {
    event.preventDefault()
    await sendEmail().then(() => setLoading(false))
    setFormData({
      name: '',
      email: '',
      feedback: '',
    })
    onClose()
  }

  async function sendEmail() {
    setLoading(true)
    const data = {
      fight_id: fightId,
      fighter_name: fighterName,
      fighter_id: fighterId,
      ...formData,
    }
    await window.emailjs.send('service_73sqnum', 'template_qjarp0q', data)
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        modal: styles.reportModal,
        overlay: styles.overlay,
      }}
    >
      <div>
        <h1>Report fight</h1>
        <p>Please give us your feedback on improving our fight data.</p>
        <form
          className={styles.report}
          onSubmit={(event) => handleSubmit({ event, onClose })}
        >
          <Input
            className={styles.inputName}
            type="text"
            label="name"
            name="name"
            onChange={updateInput}
            value={formData.name || ''}
          />
          <Input
            className={styles.inputName}
            type="email"
            label="email"
            name="email"
            onChange={updateInput}
            value={formData.email || ''}
          />
          <Input
            type="textarea"
            className={styles.inputMessage}
            label="feedback"
            name="feedback"
            rows={'6'}
            onChange={updateInput}
            value={formData.feedback || ''}
          />
          <Button isLoading={loading} isSubmit>
            Submit
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export default ReportFight
