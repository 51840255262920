import React from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { capitalizeFirstLetter } from '../helpers/utility'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import styles from './FightersTable.module.scss'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

class FightersTable extends React.Component {
  render() {
    const { fighters } = this.props

    return (
      <div className={styles.table}>
        <Table className={styles.responsiveTable}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <th>W-L-D</th>
              <th>Fights</th>
              <th>Weight</th>
              <th>Gender</th>
              <th>Country</th>
            </Tr>
          </Thead>
          <Tbody>
            {fighters.map((fighter) => {
              const totalFights = fighter.fights

              return (
                <Tr key={`fighter-${fighter.id}`}>
                  <Td>
                    <Link to={`/fighter/${fighter.id}`}>
                      <b>
                        {capitalizeFirstLetter(fighter.firstName)}
                        {fighter.nickName
                          ? ' "' + fighter.nickName + '" '
                          : ' '}
                        {capitalizeFirstLetter(fighter.lastName)}
                      </b>
                    </Link>
                  </Td>
                  <Td>
                    {' '}
                    <div className={styles.status}>
                      <span> {fighter.active ? 'Active' : 'Not active'} </span>
                      {fighter.active ? (
                        <CheckCircleOutlineIcon
                          sx={{ ml: 0.5 }}
                          color="success"
                        />
                      ) : null}
                    </div>
                  </Td>
                  <Td>
                    <div className={styles.stats}>
                      <div className={cx(styles.result, styles.green)}>
                        <span className={styles.number}>{fighter.wins}</span>
                      </div>
                      <div className={cx(styles.result, styles.red)}>
                        <span className={styles.number}>{fighter.losses}</span>
                      </div>
                      <div className={cx(styles.result, styles.blue)}>
                        <span className={styles.number}>{fighter.draws}</span>
                      </div>
                    </div>
                  </Td>
                  <Td>{totalFights === 0 ? '-' : totalFights}</Td>
                  <Td>{fighter.weight}kg</Td>
                  <Td>{capitalizeFirstLetter(fighter.gender || '-')}</Td>
                  <Td>{fighter.country || '-'}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>
    )
  }
}

export default FightersTable
