import React from 'react'
import { capitalizeFirstLetter } from '../helpers/utility'

import '@algolia/autocomplete-theme-classic/dist/theme.css'
import Avatar from './Avatar'

const BoxerHit = ({ hit, onClick }) => {
  const imageUrl = hit.imageUrl
    ? `https://res.cloudinary.com/dy1r097fd/image/upload/w_40,h_40,c_thumb,g_faces,f_auto,q_auto/v1505636095/${hit.imageUrl}`
    : null
  return (
    <div className="aa-ItemWrapper" onClick={onClick}>
      <div className="aa-ItemContent">
        <Avatar
          className="aa-ItemIcon"
          height={40}
          width={40}
          gender={hit.gender}
          imageUrl={imageUrl}
        />
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            {capitalizeFirstLetter(hit.firstName)}
            {hit.nickName ? ' "' + hit.nickName + '" ' : ' '}
            {capitalizeFirstLetter(hit.lastName)}{' '}
            {hit.weight ? `(${hit.weight} kg)` : null}
          </div>
        </div>
      </div>
      <div className="aa-ItemActions">
        <button
          className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
          type="button"
          title="Select"
        >
          <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
            <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default BoxerHit
