import React from 'react'
import cx from 'classnames'
import styles from './CreateBoxer.module.scss'
import Input from '../../Components/Input'
import Button from '../../Components/Button'
import api from '../../helpers/api'
import Loader from '../../Components/Loader'

const initialState = {
  firstName: '',
  lastName: '',
  nickName: '',
  weight: '',
  state: '',
  country: '',
  birthCountry: '',
  dateOfBirth: null,
  imageUrl: '',
  stance: '',
  gym: '',
  memberId: '',
  gender: 'male',
  verified: false,
}

class CreateBoxer extends React.Component {
  state = {
    form: initialState,
    loading: false,
    loadedBoxer: false,
    showCreated: false,
    showFailed: false,
  }

  componentDidMount() {
    const { isEdit = false, initialState } = this.props

    if (isEdit) {
      this.requestBoxer()
    }

    if (initialState) {
      this.setForm(initialState)
    }
  }

  requestBoxer = () => {
    if (this.props.isEdit && this.props.fighterId) {
      const { fighterId } = this.props
      api.get(`boxers/${fighterId}`).then((result) => {
        this.setForm(result.data)
      })
    } else {
      const {
        match: {
          params: { id },
        },
      } = this.props
      if (!id) {
        return
      }
      api.get(`boxers/${id}`).then((result) => {
        this.setForm(result.data)
      })
    }
  }

  setForm = (boxer) => {
    const { dateOfBirth, ...boxerWithoutDob } = boxer
    this.setState(
      (state) => {
        return {
          form: {
            ...state.form,
            ...boxerWithoutDob,
            dateOfBirth: Date.parse(dateOfBirth),
          },
        }
      },
      () => {
        this.setLoadedBoxer(true)
      }
    )
  }

  setLoadedBoxer = (state) => {
    this.setState({
      loadedBoxer: state,
    })
  }

  setFormField = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    })
  }

  setDob = (date) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        dateOfBirth: date,
      },
    })
  }

  setLoading = (state) => {
    this.setState({ loading: state })
  }

  scrollTop = () => {
    // NOTE: this is a bit dirty
    window.scrollTo(0, 0)
  }

  setCreated = (state) => {
    this.setState({
      showCreated: state,
    })
  }

  setFailed = (state) => {
    this.setState({
      showFailed: state,
    })
  }

  resetForm = () => {
    this.setState({
      ...this.state,
      form: initialState,
    })
  }

  editBoxer = (payload) => {
    let boxerId = ''
    const { token, fighterId, history, onCreated } = this.props

    if (!fighterId) {
      const {
        match: {
          params: { id },
        },
      } = this.props
      boxerId = id
    } else {
      boxerId = fighterId
    }

    this.setLoading(true)
    this.setFailed(false)

    api
      .put(`auth/boxers/${boxerId}`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (onCreated) {
            onCreated(response.data)
          }
          if (!fighterId) {
            // redirect to fighter page
            history.push(`/fighter/${boxerId}`)
          }

          this.resetForm()
        } else {
          this.setFailed(true)
        }
      })
      .catch(() => {
        this.setFailed(true)
      })
  }

  createBoxer = (payload) => {
    const { token, onCreated } = this.props
    this.setLoading(true)
    this.setCreated(false)
    this.setFailed(false)

    api
      .post('auth/boxers', payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setCreated(true)
          this.resetForm()

          if (onCreated) {
            onCreated(response.data)
          }
        } else {
          this.setFailed(true)
        }
      })
      .catch(() => {
        this.setFailed(true)
      })
      .finally(() => {
        this.setLoading(false)
        this.scrollTop()
      })
  }

  render() {
    const {
      form: {
        firstName,
        lastName,
        nickName,
        state,
        country,
        birthCountry,
        weight,
        dateOfBirth,
        imageUrl,
        gym,
        memberId,
        stance,
        gender,
        active,
        verified,
      },
      loading,
      showCreated,
      showFailed,
      loadedBoxer,
    } = this.state

    const { isEdit = false } = this.props

    if (isEdit && !loadedBoxer) {
      return (
        <div className={styles.container}>
          <Loader />
        </div>
      )
    }

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <h2>{isEdit ? 'Edit' : 'Create'} Boxer</h2>

          {showCreated && (
            <div className={cx(styles.message, styles.green)}>
              Boxer was created successfully.
            </div>
          )}

          {showFailed && (
            <div className={cx(styles.message, styles.red)}>
              Oops, something went wrong. Please try again.
            </div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (isEdit) {
                this.editBoxer(this.state.form)
              } else {
                this.createBoxer(this.state.form)
              }
            }}
          >
            <Input
              id="firstName"
              label="First Name"
              type="text"
              onChange={(e) => this.setFormField('firstName', e.target.value)}
              value={firstName}
            />
            <Input
              id="lastName"
              label="Last Name"
              type="text"
              onChange={(e) => this.setFormField('lastName', e.target.value)}
              value={lastName}
            />
            <Input
              id="nickName"
              label="Nick Name"
              type="text"
              onChange={(e) => this.setFormField('nickName', e.target.value)}
              value={nickName}
            />
            <Input
              id="country"
              label="Country"
              type="text"
              onChange={(e) => this.setFormField('country', e.target.value)}
              value={country}
            />
            <Input
              id="birthCountry"
              label="Country of birth"
              type="text"
              onChange={(e) =>
                this.setFormField('birthCountry', e.target.value)
              }
              value={birthCountry}
            />
            <Input
              id="state"
              label="State/region"
              type="text"
              onChange={(e) => this.setFormField('state', e.target.value)}
              value={state}
            />
            <Input
              id="weight"
              label="Weight (kg)"
              type="text"
              onChange={(e) => this.setFormField('weight', e.target.value)}
              value={weight}
            />
            <Input
              id="dateOfBirth"
              label="Date of Birth"
              type="date"
              onChange={(date) => this.setDob(date)}
              value={dateOfBirth}
            />
            <Input
              id="imageUrl"
              label="Boxer Image Url"
              type="text"
              onChange={(e) => this.setFormField('imageUrl', e.target.value)}
              value={imageUrl}
            />
            <Input
              id="gym"
              label="Gym"
              type="text"
              onChange={(e) => this.setFormField('gym', e.target.value)}
              value={gym}
            />
            <Input
              id="memberId"
              label="Member ID"
              type="text"
              onChange={(e) => this.setFormField('memberId', e.target.value)}
              value={memberId}
            />
            <Input
              id="stance"
              label="Stance"
              type="text"
              onChange={(e) => this.setFormField('stance', e.target.value)}
              value={stance}
            />
            <Input
              id="gender"
              label="Gender"
              type="select"
              onChange={(e) => this.setFormField('gender', e.target.value)}
              value={gender}
              options={[
                { key: 'male', value: 'Male' },
                { key: 'female', value: 'Female' },
              ]}
            />
            <Input
              id="active"
              label="Status"
              type="select"
              onChange={(e) => this.setFormField('active', e.target.value)}
              value={active}
              options={[
                { key: 'true', value: 'Active' },
                { key: 'false', value: 'Not active' },
              ]}
            />
            <Input
              id="verified"
              label="Verified"
              type="checkbox"
              onChange={(checked) => this.setFormField('verified', checked)}
              value={verified}
            />
            <Button isLoading={loading} isSubmit>
              {isEdit ? 'Edit' : 'Create'} Boxer
            </Button>
          </form>
        </div>
      </div>
    )
  }
}

export default CreateBoxer
