import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import styles from './Contact.module.scss'

class Terms extends React.Component {
  render() {
    return (
      <Row className={styles.row}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={styles.container}>
            <h1>Terms & Conditions</h1>
            <p>
              <span>
                Thank you for visiting bookingbook.com (&lsquo;
                <strong>our website&rsquo;</strong>). Our website is owned and
                operated by The Boxing Book Pty Ltd. (ACN 639500860) (
                <strong>&apos;us&apos;</strong> <strong>&apos;we&apos;</strong>{' '}
                or <strong>&apos;our&apos;)</strong>.&nbsp;
              </span>
            </p>
            <p>
              <span>
                By accessing and/or using our website and related services, you
                agree to these Terms and Conditions, which include our&nbsp;
              </span>
              <a href="https://www.boxingbook.com/privacy">
                <span>Privacy Policy</span>
              </a>
              <span>
                &nbsp;(<strong>&lsquo;these</strong>{' '}
                <strong>Terms&rsquo;</strong>). You should review our&nbsp;
              </span>
              <a href="https://www.boxingbook.com/privacy">
                <span>Privacy Policy</span>
              </a>
              <span>
                &nbsp; and these Terms carefully and immediately cease using our
                website if you do not agree to these Terms. In these Terms,
                &apos;us&apos;, &apos;we&apos; and &apos;our&apos; means The
                Boxing Book Pty Ltd.
              </span>
            </p>
            <ol start="1" type="1">
              <li>
                <strong>
                  <span>Collection Notice</span>
                </strong>
              </li>
            </ol>
            <p>
              <span>
                We collect personal information about you in order to provide
                you with our services and for the other purposes set out in
                our&nbsp;
              </span>
              <a href="https://www.boxingbook.com/privacy">
                <span>Privacy Policy</span>
              </a>
              <span>
                . We may request information from you such as, name, age, weight
                and fight detailed record.
              </span>
            </p>
            <p>
              <span>
                The information you provide will be collected by or on behalf of
                us and may be disclosed to third parties that help us deliver
                our services (including information technology suppliers,
                communication suppliers and our business partners) or as
                required by law. If you do not provide this information, we may
                not be able to provide all our services to you.
              </span>
            </p>
            <p>
              <span>Our&nbsp;</span>
              <a href="https://www.boxingbook.com/privacy">
                <span>Privacy Policy</span>
              </a>
              <span>
                &nbsp; explains: (i) how we store and use, and how you may
                access and correct your personal information; (ii) how you can
                lodge a complaint regarding the handling of your personal
                information; and (iii) how we will handle any complaint. If you
                would like any further information about our privacy policies or
                practices, please contact us at&nbsp;
              </span>
              <a href="mailto:admin@boxingbook.com">
                <span>admin@boxingbook.com</span>
              </a>
              <span>
                &nbsp;. By providing your personal information to us, you
                consent to the collection, use, storage and disclosure of that
                information as described in the&nbsp;
              </span>
              <a href="https://www.boxingbook.com/privacy">
                <span>Privacy Policy</span>
              </a>
              <span>&nbsp; and this collection notice.</span>
            </p>
            <ol>
              <li>
                <strong>
                  <span>
                    Accuracy, completeness and timeliness of information
                  </span>
                </strong>
                <span>
                  <br />
                  &nbsp;The information on our website is not comprehensive and
                  is intended to provide a summary of the subject matter
                  covered. While we use all reasonable attempts to ensure the
                  accuracy and completeness of the information on our website,
                  to the extent permitted by law, including the Australian
                  Consumer Law, we make no warranty regarding the information on
                  our website. You should monitor any changes to the information
                  contained on our website. We are not liable to you or anyone
                  else if interference with or damage to your computer systems
                  occurs in connection with the use of our website or a linked
                  website. You must take your own precautions to ensure that
                  whatever you select for your use from our website is free of
                  viruses or anything else (such as worms or Trojan horses) that
                  may interfere with or damage the operations of your computer
                  systems. We may, from time to time and without notice, change
                  or add to the website (including the Terms) or the
                  information, products or services described in it. However, we
                  do not undertake to keep the website updated. We are not
                  liable to you or anyone else if errors occur in the
                  information on the website or if that information is not
                  up-to-date.
                </span>
              </li>
              <li>
                <strong>
                  <span>Linked sites</span>
                </strong>
                <span>
                  <br />
                  &nbsp;Our website may contain links to websites operated by
                  third parties. Those links are provided for convenience and
                  may not remain current or be maintained. We do not endorse and
                  are not responsible for the content on those linked websites
                  and have no control over or rights in those linked websites.
                  Unless expressly stated otherwise, we are not responsible for
                  the privacy practices of, or any content on, those linked
                  websites, and have no control over or rights in those linked
                  websites. The privacy policies that apply to those other
                  websites may differ substantially from this&nbsp;
                </span>
                <a href="https://www.boxingbook.com/privacy">
                  <span>Privacy Policy</span>
                </a>
                <span>
                  . &nbsp;We encourage you to read the privacy policies of
                  linked websites before using them.
                </span>
              </li>
              <li>
                <strong>
                  <span>Intellectual property rights</span>
                </strong>
                <span>
                  <br /> Unless otherwise indicated, we own or license from
                  third parties all rights, title and interest (including
                  copyright, designs, patents, trademarks and other intellectual
                  property rights) in our website and in all of the material
                  (including all text, graphics, logos, audio and software) made
                  available on our website{' '}
                  <strong>(&lsquo;Content&rsquo;)</strong>. Your use of our
                  website and use of and access to any Content does not grant or
                  transfer any rights, title or interest to you in relation to
                  our website or the Content. However, we do grant you a licence
                  to access the website and view the Content on the terms and
                  conditions set out in these Terms and, where applicable, as
                  expressly authorised by us and/or our third party licensors.
                  Any reproduction or redistribution of our website or the
                  Content is prohibited and may result in civil and criminal
                  penalties. In addition, you must not copy the Content to any
                  other server, location or support for publication,
                  reproduction or distribution. All other use, copying or
                  reproduction of our website, the Content or any part of it is
                  prohibited, except to the extent permitted by law. However,
                  you are permitted to make one copy of the material on our
                  website for the purpose of viewing the Content for your own
                  personal use. &nbsp;You must not modify the paper or digital
                  copies of any materials you have printed off or downloaded in
                  any way, and you must not use any illustrations, photographs,
                  video or any graphics separately from any accompanying text.
                  &nbsp;Our status (and that of any identified contributors) as
                  the authors of content on our website must always be
                  acknowledged.
                </span>
              </li>
              <li>
                <strong>
                  <span>No commercial use</span>
                </strong>
                <span>
                  <br />
                  &nbsp;Our website is for your personal, non-commercial use
                  only. You may not modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, commercially exploit,
                  create derivative works from, transfer, or sell any Content,
                  software, products or services contained within our website.
                  You may not use our website, or any of its Content, to further
                  any commercial purpose, including any advertising or
                  advertising revenue generation activity on your own website.
                </span>
              </li>
            </ol>
            <ol start="6" type="1">
              <li>
                <strong>
                  <span>Unacceptable activity</span>
                </strong>
                <span>
                  <br />
                  &nbsp;You must not do any act that we would deem to be
                  inappropriate, is unlawful or is prohibited by any laws
                  applicable to our website, including but not limited to:
                </span>
                <ul type="circle">
                  <li>
                    <span>
                      any reproduction or redistribution of our website or the
                      Content&nbsp;
                    </span>
                  </li>
                  <li>
                    <span>
                      any copying of the Content to any other server, location
                      or support for publication, reproduction or distribution,
                      including using automated device, software, process or
                      means to access, retrieve, scrape, or index our website or
                      the Content;
                    </span>
                  </li>
                  <li>
                    <span>
                      using any device, software, process or means to interfere
                      or attempt to interfere with the proper working on our
                      website, including any attack on our website via a
                      denial-of-service attack;
                    </span>
                  </li>
                  <li>
                    <span>
                      undertake any action that will impose a burden or make
                      excessive traffic demands on our infrastructure that we
                      deem, in our sole discretion to be unreasonable or
                      disproportionate usage of our website;
                    </span>
                  </li>
                  <li>
                    <span>
                      any act that would constitute a breach of either the
                      privacy (including uploading private or personal
                      information without an individual&apos;s consent) or any
                      other of the legal rights of individuals;
                    </span>
                  </li>
                  <li>
                    <span>
                      using our website to defame or libel us, our employees or
                      other individuals;
                    </span>
                  </li>
                  <li>
                    <span>
                      uploading files that contain viruses that may cause damage
                      to our property or the property of other
                      individuals;&nbsp;
                    </span>
                  </li>
                  <li>
                    <span>
                      posting or transmitting to our website any non-authorised
                      material including, but not limited to, material that is,
                      in our opinion, likely to cause annoyance, or which is
                      defamatory, racist, obscene, threatening, pornographic or
                      otherwise or which is detrimental to or in violation of
                      our systems or a third party&apos;s systems or network
                      security; or
                    </span>
                  </li>
                  <li>
                    <span>
                      use our website or any Content in any manner which is, in
                      our sole discretion, not reasonable and/or not for the
                      purpose which it is made available.
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              <span>
                If we allow you to post any information to our website, we have
                the right to take down this information at our sole discretion
                and without notice.
              </span>
            </p>
            <p>
              <span>
                We reserve the right to exercise whatever means we deem
                necessary to prevent unauthorised access to or use of our
                website, including, but not limited to, instituting
                technological barriers, or reporting your conduct to any person
                or entity.
              </span>
            </p>
            <ol>
              <li>
                <strong>
                  <span>Warranties and disclaimers</span>
                </strong>
                <span>
                  <br />
                  &nbsp;To the maximum extent permitted by law, including the
                  Australian Consumer Law, we make no warranties or
                  representations about our website or the Content, including
                  but not limited to warranties or representations that they
                  will be complete, accurate or up-to-date, that access will be
                  uninterrupted or error-free or free from viruses, or that our
                  website will be secure. We reserve the right to restrict,
                  suspend or terminate without notice your access to our
                  website, any Content, or any feature of our website at any
                  time without notice and we will not be responsible for any
                  loss, cost, damage or liability that may arise as a result
                </span>
              </li>
              <li>
                <strong>
                  <span>Liability</span>
                </strong>
              </li>
            </ol>
            <p>
              <span>
                To the maximum extent permitted by law, including the Australian
                Consumer Law, in no event shall we be liable for any direct and
                indirect loss, damage or expense &ndash; irrespective of the
                manner in which it occurs &ndash; which may be suffered due to
                your use of our website and/or the information or materials
                contained on it, or as a result of the inaccessibility of our
                website and/or the fact that certain information or materials
                contained on it are incorrect, incomplete or not up-to-date.
              </span>
            </p>
            <p>
              <span>
                We will not be liable to you for any loss or damage, whether in
                contract, tort (including negligence), breach of statutory duty,
                or otherwise, even if foreseeable, arising under or in
                connection with:
              </span>
            </p>
            <ul>
              <li>
                <span>use of, or inability to use, our website; or</span>
              </li>
              <li>
                <span>
                  use of or reliance on any content displayed on our website.
                </span>
              </li>
            </ul>
            <p>
              <span>In particular, we will not be liable for:</span>
            </p>
            <ul>
              <li>
                <span>loss of profits, sales, business, or revenue; or</span>
              </li>
              <li>
                <span>business interruption; or</span>
              </li>
              <li>
                <span>loss of anticipated savings; or</span>
              </li>
              <li>
                <span>
                  loss of business opportunity, goodwill or reputation; or
                </span>
              </li>
              <li>
                <span>any indirect or consequential loss or damage.</span>
              </li>
            </ul>
            <ol>
              <li>
                <strong>
                  <span>Rules about linking to our site</span>
                </strong>
              </li>
            </ol>
            <p>
              <span>
                You may link to our website home page, provided you do so in a
                way that is fair and legal and does not damage our reputation or
                take advantage of it. &nbsp; You must not establish a link in
                such a way as to suggest any form of association, approval or
                endorsement on our part where none exists. &nbsp;You must not
                establish a link to our website in any site that is not owned by
                you. &nbsp; Our website must not be framed on any other site,
                nor may you create a link to any part of our website other than
                the home page. &nbsp;We reserve the right to withdraw linking
                permission without notice. &nbsp;If you wish to link to or make
                any use of content on our site other than that set out above,
                please contact&nbsp;
              </span>
              <a href="mailto:admin@boxingbook.com">
                <span>admin@boxingbook.com</span>
              </a>
              <span>.</span>
            </p>
            <p>
              <span>&nbsp;</span>
            </p>
            <div>
              <ol>
                <li>
                  <strong>
                    <span>We may make changes to these Terms</span>
                  </strong>
                </li>
              </ol>
            </div>
            <p>
              <span>
                We may amend these Terms from time to time. Every time you wish
                to use our website, please check these Terms to ensure you
                understand the terms that apply at that time.&nbsp;
              </span>
            </p>
            <ol start="11" type="1">
              <li>
                <strong>
                  <span>Jurisdiction and governing law</span>
                </strong>
                <span>
                  <br />
                  &nbsp;Your use of the website and these Terms are governed by
                  the law of Queensland, Australia and you submit to the
                  non-exclusive jurisdiction of the courts exercising
                  jurisdiction in Queensland, Australia.
                </span>
              </li>
            </ol>
            <p>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Last amended:</span>
              </strong>
              <span>&nbsp;[22 March, 2022]</span>
            </p>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Terms
