import axios from 'axios'
let api = 'https://am-boxing-record.herokuapp.com/api/'
;['127.0.0.1', 'localhost', 'am-box-rec-web-staging'].forEach(function (host) {
  if (window.location.origin.includes(host)) {
    api = 'https://aus-corp-boxing-staging.herokuapp.com/api/'
  }
})
export default axios.create({
  baseURL: api,
})
