import React from 'react'

class Logout extends React.Component {
  componentWillMount() {
    if (this.props.loggedIn) {
      this.props.logout()
    }
    this.props.history.push('/')
  }

  render() {
    return null
  }
}

export default Logout
