import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './CreateBoxer.module.scss'
import Loader from '../../Components/Loader'
import Input from '../../Components/Input'
import Button from '../../Components/Button'
import api from '../../helpers/api'
import cx from 'classnames'

const CreateEvent = ({ token, initialState = {}, onCreated }) => {
  const initialEvent = () => {
    return {
      date: null,
      name: initialState.name || '',
      country: '',
      state: '',
      venue: '',
    }
  }
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [showCreated, setShowCreated] = useState(false)
  const [showFailed, setShowFailed] = useState(false)
  const [event, setEvent] = useState(initialEvent())

  const reset = () => {
    setEvent(initialEvent())
  }

  const isEdit = !!id

  useEffect(() => {
    if (isEdit && !event.id) {
      setIsLoading(true)
      api
        .get(`events/${id}`)
        .then((result) => {
          setEvent(result.data)
          setIsLoading(false)
        })
        .finally(() => setIsLoading(false))
    }
  }, [isEdit, event.id, id])

  function updateField(key, value) {
    setEvent({ ...event, [key]: value })
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2>{isEdit ? 'Edit' : 'Create'} Event</h2>

        {showCreated && (
          <div className={cx(styles.message, styles.green)}>
            Event was {isEdit ? 'updated' : 'created'} successfully.
          </div>
        )}

        {showFailed && (
          <div className={cx(styles.message, styles.red)}>
            Oops, something went wrong. Please try again.
          </div>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault()
            setIsLoading(true)
            let method = 'post'
            let url = ''
            if (isEdit) {
              method = 'put'
              url = `/${id}`
            }
            api[method](`auth/events${url}`, event, {
              headers: {
                Authorization: token,
              },
            })
              .then((response) => {
                setShowCreated(true)
                if (onCreated) {
                  onCreated(response.data)
                }
                reset()
              })
              .catch(() => {
                setShowFailed(true)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        >
          <Input
            id="date"
            label="Date"
            type="date"
            onChange={(value) => updateField('date', value)}
            value={event.date}
          />
          <Input
            id="name"
            label="Tournament Name"
            type="text"
            onChange={(e) => updateField('name', e.target.value)}
            value={event.name}
          />
          <Input
            id="country"
            label="Country"
            type="text"
            onChange={(e) => updateField('country', e.target.value)}
            value={event.country}
          />
          <Input
            id="state"
            label="State"
            type="text"
            onChange={(e) => updateField('state', e.target.value)}
            value={event.state}
          />
          <Input
            id="venue"
            label="Venue"
            type="text"
            onChange={(e) => updateField('venue', e.target.value)}
            value={event.venue}
          />
          <Button isLoading={isLoading} isSubmit>
            {isEdit ? 'Edit' : 'Create'} Event
          </Button>
        </form>
      </div>
    </div>
  )
}

export default CreateEvent
