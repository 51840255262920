import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../Components/Avatar'
import styles from './Featured.module.scss'
import cx from 'classnames'
import { capitalizeFirstLetter } from '../helpers/utility'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

class Featured extends React.Component {
  render() {
    const { fighter } = this.props
    const pictureUrl = fighter.imageUrl
      ? `https://res.cloudinary.com/dy1r097fd/image/upload/f_auto,q_auto/v1505636095/${fighter.imageUrl}`
      : null

    return (
      <div className={styles.featured}>
        <Link to={`/fighter/${fighter.id}`}>
          <Avatar
            imageUrl={pictureUrl}
            height="60px"
            width="60px"
            className={styles.avatar}
            gender={fighter.gender}
          />
          <div className={styles.info}>
            <div className={styles.name}>
              {capitalizeFirstLetter(fighter.firstName)}{' '}
              {capitalizeFirstLetter(fighter.lastName)}
            </div>
            <div className={styles.param}>
              Weight: {fighter.weight ? `${fighter.weight} kg` : '-'}
            </div>
            <div className={styles.param}>
              Country: {fighter.country || '-'}
            </div>
            <div className={styles.param}>Gender: {fighter.gender || '-'}</div>
            <div className={styles.status}>
              <span> Status: {fighter.active ? 'Active' : 'Not active'} </span>
              {fighter.active ? (
                <CheckCircleOutlineIcon sx={{ ml: 0.5 }} color="success" />
              ) : null}
            </div>
          </div>
          <div className={styles.statContainer}>
            <div className={styles.stats}>
              <div className={cx(styles.result, styles.green)}>
                <span className={styles.number}>{fighter.wins || 0}</span>
              </div>
              <div className={cx(styles.result, styles.red)}>
                <span className={styles.number}>{fighter.losses || 0}</span>
              </div>
              <div className={cx(styles.result, styles.blue)}>
                <span className={styles.number}>{fighter.draws || 0}</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default Featured
