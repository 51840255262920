import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import Loader from '../Components/Loader'
import api from '../helpers/api'
import styles from './Event.module.scss'
import moment from 'moment'
import { ReactComponent as NotFoundIcon } from '../icons/search-remove.svg'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import cx from 'classnames'
import { capitalizeFirstLetter } from '../helpers/utility'
import { ReactComponent as ArrowUp } from '../icons/arrow-up-1.svg'
import { ReactComponent as ArrowDown } from '../icons/arrow-down-1.svg'
import { Pie, PieChart, Cell, Legend } from 'recharts'
import confirm from '../helpers/confirm'

const Event = ({ token, loggedIn, history }) => {
  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  const [showStats, setShowStats] = useState(!isMobile)
  const [event, setEvent] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`events/${id}`)
      .then((result) => {
        setEvent(result.data)
      })
      .finally(() => setIsLoading(false))
  }, [id])

  function deleteEvent() {
    confirm(() => {
      api
        .delete(`auth/events/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          history.push('/events')
        })
    }, 'You want to delete this event?')
  }

  const fighterHasProfile = (fighter) => {
    return !!fighter?.boxer
  }

  if (isLoading) {
    return <Loader />
  }
  const fightsSplit = {}

  let sortedFights = event.fights.sort(
    (a, b) => moment.utc(a.date) - moment.utc(b.date)
  )

  sortedFights.map(function (fight) {
    if (fightsSplit[moment.utc(fight.date)] !== undefined) {
      fightsSplit[moment.utc(fight.date)].push(fight)
    } else {
      fightsSplit[moment.utc(fight.date)] = [fight]
    }
    return []
  })

  const genderData = Object.entries(event.genderDistribution).map(([k, v]) => ({
    name: k,
    value: v,
  }))

  const resultsData = Object.entries(event.resultDistribution).map(
    ([k, v]) => ({
      name: k,
      value: v,
    })
  )

  const COLORS_GENDER = ['#D84B20', '#F4A900']

  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#4C9141',
    '#6C6874',
    '#ED760E',
    '#0E294B',
    '#9DA1AA',
    '#606E8C',
    '#3F888F',
    '#CF3476',
  ]

  const labelFormatter = (value) => {
    return Math.round(value.percent * 100).toString() + '%'
  }

  return (
    <Row className={styles.row}>
      <Col xs={12} sm={12} md={3} lg={3}>
        <div className={styles.event}>
          <div className={styles.field}>
            <strong>Event</strong>: {event.name}
          </div>
          <div className={styles.field}>
            <strong>Country</strong>: {event.country}
          </div>
          <div className={styles.field}>
            <strong>State/Region</strong>: {event.state}
          </div>
          <div className={styles.field}>
            <strong>Venue</strong>: {event.venue || '-'}
          </div>
          <div className={styles.field}>
            <strong>Year</strong>: {moment.utc(event.date).format('YYYY')}
          </div>
        </div>
        <div
          className={styles.advancedLink}
          onClick={() => setShowStats(!showStats)}
        >
          Event Statistics
          {showStats ? <ArrowUp /> : <ArrowDown />}
        </div>
        {showStats && event.fights.length > 0 ? (
          <div style={{ paddingTop: '12px' }}>
            <div className={styles.field}>
              <strong>Results:</strong>
            </div>
            <div style={{ margin: '12px' }}>
              <PieChart width={275} height={300}>
                <Legend verticalAlign="top" height={36} />
                <Pie
                  data={resultsData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={labelFormatter}
                  unit={'%'}
                  outerRadius={75}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {resultsData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div className={styles.fieldLegend}>
                <p>*UNK = unknown result.</p>
              </div>
            </div>
            <div className={styles.field}>
              <strong>Gender:</strong>
            </div>
            <div style={{ margin: '12px' }}>
              <PieChart width={275} height={225}>
                <Legend verticalAlign="top" height={36} />
                <Pie
                  data={genderData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={labelFormatter}
                  unit={'%'}
                  outerRadius={75}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {genderData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS_GENDER[index]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
        ) : null}
      </Col>

      <Col xs={12} sm={12} md={9} lg={9}>
        <div className={styles.results}>
          <div className={styles.top}>
            <h2>Fights</h2>
            <div className={styles.topAdmin}>
              {loggedIn && (
                <Link
                  className={styles.adminLink}
                  to={`/admin/events/edit/${id}`}
                >
                  Edit Event
                </Link>
              )}
              {loggedIn && (
                <Link
                  className={styles.adminLink}
                  onClick={() => deleteEvent()}
                >
                  Delete Event
                </Link>
              )}
            </div>
          </div>
          {event.fights.length > 0 ? (
            Object.keys(fightsSplit).map((fight, index) => {
              return (
                <>
                  {Object.keys(fightsSplit).length > 1 ? (
                    <div className={styles.days}>
                      <h2>Day {index + 1}</h2>
                    </div>
                  ) : null}
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>W-L-D</Th>
                        <Th>Winner</Th>
                        <Th>Result</Th>
                        <Th>Opponent</Th>
                        <Th>W-L-D</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {fightsSplit[fight]
                        .sort((a, b) => a.id - b.id)
                        .map((fight) => {
                          const opponent = fight.fighters.find(
                            (fighter) => !fighter.winner
                          )
                          //don't always have a winner. TODO: someone smarter than me rebuild this
                          const winner = fight.fighters.find(
                            (fighter) => fighter.id !== opponent.id
                          )
                          return (
                            <Tr key={`fight-${fight.id}`}>
                              <Td className={styles.cell}>
                                {moment
                                  .utc(fight.date, 'YYYY-MM-DD')
                                  .format('DD/MM/YYYY')}
                              </Td>

                              <Td className={styles.cell}>
                                {fighterHasProfile(winner) ? (
                                  <div className={styles.resultsAtTheTime}>
                                    <div
                                      className={cx(
                                        styles.result,
                                        styles.green
                                      )}
                                    >
                                      <span className={styles.number}>
                                        {winner.winsAtTime}
                                      </span>
                                    </div>
                                    <div
                                      className={cx(styles.result, styles.red)}
                                    >
                                      <span className={styles.number}>
                                        {winner.lossesAtTime}
                                      </span>
                                    </div>
                                    <div
                                      className={cx(styles.result, styles.blue)}
                                    >
                                      <span className={styles.number}>
                                        {winner.drawsAtTime}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </Td>
                              <Td className={styles.cell}>
                                {fighterHasProfile(winner) ? (
                                  <Link to={`/fighter/${winner.boxer.id}`}>
                                    {capitalizeFirstLetter(
                                      winner.boxer.firstName
                                    )}{' '}
                                    {capitalizeFirstLetter(
                                      winner.boxer.lastName
                                    )}{' '}
                                    {winner.weight
                                      ? `(${winner.weight} kg)`
                                      : null}
                                  </Link>
                                ) : (
                                  <>
                                    {winner?.name ? ' ' : null}
                                    {winner?.weight
                                      ? `(${winner.weight} kg)`
                                      : null}
                                  </>
                                )}
                              </Td>
                              <Td className={styles.cell}>
                                <span
                                  className={cx(
                                    styles.badge,
                                    fight.result === 'D'
                                      ? styles.draw
                                      : fight.result === 'EXH'
                                      ? styles.exh
                                      : styles.win
                                  )}
                                >
                                  {fight.result === undefined ||
                                  fight.result === null ||
                                  fight.result === 'UNK'
                                    ? 'WIN'
                                    : fight.result}
                                </span>
                              </Td>
                              <Td className={styles.cell}>
                                {fighterHasProfile(opponent) ? (
                                  <Link to={`/fighter/${opponent.boxer.id}`}>
                                    {capitalizeFirstLetter(
                                      opponent.boxer.firstName
                                    )}{' '}
                                    {capitalizeFirstLetter(
                                      opponent.boxer.lastName
                                    )}{' '}
                                    {opponent.weight
                                      ? `(${opponent.weight} kg)`
                                      : null}
                                  </Link>
                                ) : (
                                  <>
                                    {opponent?.name ? ' ' : null}
                                    {opponent?.weight
                                      ? `(${opponent.weight} kg)`
                                      : null}
                                  </>
                                )}
                              </Td>
                              <Td className={styles.cell}>
                                {fighterHasProfile(opponent) ? (
                                  <div className={styles.resultsAtTheTime}>
                                    <div
                                      className={cx(
                                        styles.result,
                                        styles.green
                                      )}
                                    >
                                      <span className={styles.number}>
                                        {opponent.winsAtTime}
                                      </span>
                                    </div>
                                    <div
                                      className={cx(styles.result, styles.red)}
                                    >
                                      <span className={styles.number}>
                                        {opponent.lossesAtTime}
                                      </span>
                                    </div>
                                    <div
                                      className={cx(styles.result, styles.blue)}
                                    >
                                      <span className={styles.number}>
                                        {opponent.drawsAtTime}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </Td>
                            </Tr>
                          )
                        })}
                    </Tbody>
                  </Table>
                </>
              )
            })
          ) : (
            <div className={styles.notfound}>
              <NotFoundIcon />
              <span>No fights were added for this event</span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Event
