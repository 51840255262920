import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from '../Components/Modals/Modal.module.scss'
import Input from '../Components/Input'
import Button from '../Components/Button'
import api from './api'
import queryString from 'query-string'
import moment from 'moment'

function MergeFighter({ isOpen, onClose, fighterName, fighterId, token }) {
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [suggestedFighters, setSuggestedFighter] = useState([])
  const [mergedFighterId, setMergedFighterId] = useState()
  const [error, setError] = useState()

  function requestSuggestedFighters(fighterName) {
    setLoading(true)
    const fighterOne = fighterName.split(' ')

    const fighterQuery = {
      firstName: fighterOne[0],
      lastName: fighterOne[1],
    }

    api
      .get(`auth/boxers/?${queryString.stringify(fighterQuery)}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const filteredCurrent = response.data.content.filter(
          (fighter) => fighter.id !== parseInt(fighterId)
        )
        setSuggestedFighter(filteredCurrent)
        setLoading(false)
      })
  }

  async function handleSubmit({ event, onClose }) {
    event.preventDefault()
    if (suggestedFighters.length > 0) {
      setLoading(true)
      api
        .put(
          `auth/boxers/${mergedFighterId}/merge`,
          { mergeIntoId: fighterId },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          setLoading(false)
          setError('')
          onClose()
        })
        .catch(() => {
          setError('Unable to merge fighter.')
        })
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center
      classNames={{
        modal: styles.reportModal,
        overlay: styles.overlay,
      }}
    >
      <div>
        <h1>Merge Fighter</h1>
        <p>
          Please select the fighter you want to merge with <b>{fighterName}</b>.
        </p>
        <form
          className={styles.report}
          onSubmit={(event) => handleSubmit({ event, onClose })}
        >
          <div className={styles.search}>
            <Input
              id="searchFighter"
              label="Search for existing boxer"
              type="text"
              value={searchText}
              placeholder="Find boxers"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button
              className={styles.searchButton}
              isLoading={loading}
              onClick={() => requestSuggestedFighters(searchText)}
            >
              Search
            </Button>
          </div>
          {suggestedFighters.length > 0 && (
            <>
              <Input
                id="existingFighterId"
                label="Existing Boxer"
                type="select"
                onChange={(e) => setMergedFighterId(e.target.value)}
                value={mergedFighterId}
                options={[
                  {
                    key: '',
                    value: 'Choose Boxer',
                  },
                  ...suggestedFighters.map((fighter) => ({
                    key: fighter.id,
                    value: `${fighter.firstName} ${
                      fighter.lastName
                    } - Weight: ${fighter.weight || '-'}kg - DoB: ${moment
                      .utc(fighter.dateOfBirth)
                      .format('DD/MM/YYYY')} - Gym: ${fighter.gym}`,
                  })),
                ]}
              />
              <Button
                isLoading={loading || suggestedFighters.length === 0}
                isSubmit
              >
                Merge
              </Button>
              {error !== '' && <p className={styles.error}>{error}</p>}
            </>
          )}
        </form>
      </div>
    </Modal>
  )
}

export default MergeFighter
