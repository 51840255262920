import React from 'react'
import styles from './Loader.module.scss'
import { ReactComponent as LoaderIcon } from '../icons/loading-circle-1.svg'

const Loader = () => {
  return (
    <div className={styles.loader}>
      <span>
        <LoaderIcon />
      </span>
    </div>
  )
}

export default Loader
